import { JwtUser } from '@api/requests';

export enum Role {
    ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    TEST_ROLE = 'TEST_ROLE',
}

export function getRoleDisplayName(role: Role): string {
    switch (role) {
        case Role.ORGANIZATION_ADMIN:
            return 'Admin';
        case Role.SYSTEM_ADMIN:
            return 'System Admin';
        case Role.TEST_ROLE:
            return 'Test';
        default:
            return role;
    }
}

export default interface User {
    id: string;
    createdAt: number;
    updatedAt: number;
    email: string;
    firstName?: string;
    lastName?: string;
    emailVerifiedAt?: number;
    organizationId?: number | null;
    organizationName?: string | null;
    /**
     * Combined system-level and organization-level roles. Applies across all organizations
     */
    roles: Role[];
}

export const getAllRoles = (user?: User | JwtUser | null): Role[] => {
    return user?.roles ?? [];
};

export function hasAllRoles(user: User | JwtUser | undefined | null, ...roles: Role[]): user is User {
    const userRoles = getAllRoles(user);
    return roles.every((r) => userRoles.includes(r));
}

export function hasAnyRoles(user: User | JwtUser | undefined | null, ...roles: Role[]): user is User {
    const userRoles = getAllRoles(user);
    return roles.some((r) => userRoles.includes(r));
}
