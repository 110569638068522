import { Context, Plugin } from '@nuxt/types';
import ApiService from '@client/sevices/ApiService';
import NuxtService from '@client/sevices/NuxtService';
import { isBlank } from '@shared/util/TypeGuards';

const ServicesPlugin: Plugin = (context: Context) => {
    const { $sentry, $config, $auth } = context;

    ApiService.init(context);
    NuxtService.init(context);

    if (process.client) {
        // configure sentry
        $sentry.configureScope((scope) => {
            scope.addEventProcessor((event) => {
                event.environment = $config.sentryEnv || $config.envName;
                if (!isBlank($config.release)) {
                    event.release = $config.release;
                }
                event.user = $auth?.user;
                return event;
            });
        });
    }
};

export default ServicesPlugin;
