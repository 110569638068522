export const AlertBanner = () => import('../../src/components/AlertBanner.vue' /* webpackChunkName: "components/alert-banner" */).then(c => wrapFunctional(c.default || c))
export const AlertDialog = () => import('../../src/components/AlertDialog.vue' /* webpackChunkName: "components/alert-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../src/components/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const Directory = () => import('../../src/components/Directory.vue' /* webpackChunkName: "components/directory" */).then(c => wrapFunctional(c.default || c))
export const DirectoryTypes = () => import('../../src/components/DirectoryTypes.ts' /* webpackChunkName: "components/directory-types" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../src/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HorizontalDivider = () => import('../../src/components/HorizontalDivider.vue' /* webpackChunkName: "components/horizontal-divider" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../src/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoTypes = () => import('../../src/components/LogoTypes.ts' /* webpackChunkName: "components/logo-types" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../src/components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NavBarDrawer = () => import('../../src/components/NavBarDrawer.vue' /* webpackChunkName: "components/nav-bar-drawer" */).then(c => wrapFunctional(c.default || c))
export const ProductTitle = () => import('../../src/components/ProductTitle.vue' /* webpackChunkName: "components/product-title" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../src/components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const WelcomeCard = () => import('../../src/components/WelcomeCard.vue' /* webpackChunkName: "components/welcome-card" */).then(c => wrapFunctional(c.default || c))
export const AssessmentHomeCard = () => import('../../src/components/assessment/AssessmentHomeCard.vue' /* webpackChunkName: "components/assessment-home-card" */).then(c => wrapFunctional(c.default || c))
export const AssessmentLikertQuestionView = () => import('../../src/components/assessment/LikertQuestionView.vue' /* webpackChunkName: "components/assessment-likert-question-view" */).then(c => wrapFunctional(c.default || c))
export const AssessmentLikertScaleView = () => import('../../src/components/assessment/LikertScaleView.vue' /* webpackChunkName: "components/assessment-likert-scale-view" */).then(c => wrapFunctional(c.default || c))
export const AssessmentLongTextQuestionView = () => import('../../src/components/assessment/LongTextQuestionView.vue' /* webpackChunkName: "components/assessment-long-text-question-view" */).then(c => wrapFunctional(c.default || c))
export const AssessmentProcessingResultsCard = () => import('../../src/components/assessment/ProcessingResultsCard.vue' /* webpackChunkName: "components/assessment-processing-results-card" */).then(c => wrapFunctional(c.default || c))
export const AssessmentQuestion = () => import('../../src/components/assessment/Question.vue' /* webpackChunkName: "components/assessment-question" */).then(c => wrapFunctional(c.default || c))
export const AssessmentQuestionText = () => import('../../src/components/assessment/QuestionText.vue' /* webpackChunkName: "components/assessment-question-text" */).then(c => wrapFunctional(c.default || c))
export const AssessmentRadioChoicesView = () => import('../../src/components/assessment/RadioChoicesView.vue' /* webpackChunkName: "components/assessment-radio-choices-view" */).then(c => wrapFunctional(c.default || c))
export const AssessmentRadioQuestionView = () => import('../../src/components/assessment/RadioQuestionView.vue' /* webpackChunkName: "components/assessment-radio-question-view" */).then(c => wrapFunctional(c.default || c))
export const AssessmentRangeQuestion = () => import('../../src/components/assessment/RangeQuestion.vue' /* webpackChunkName: "components/assessment-range-question" */).then(c => wrapFunctional(c.default || c))
export const AssessmentTextOnlyQuestionView = () => import('../../src/components/assessment/TextOnlyQuestionView.vue' /* webpackChunkName: "components/assessment-text-only-question-view" */).then(c => wrapFunctional(c.default || c))
export const AssessmentUserAssessmentView = () => import('../../src/components/assessment/UserAssessmentView.vue' /* webpackChunkName: "components/assessment-user-assessment-view" */).then(c => wrapFunctional(c.default || c))
export const AdminManageTeamDirectory = () => import('../../src/components/admin/ManageTeamDirectory.vue' /* webpackChunkName: "components/admin-manage-team-directory" */).then(c => wrapFunctional(c.default || c))
export const AdminMemberInfoTooltip = () => import('../../src/components/admin/MemberInfoTooltip.vue' /* webpackChunkName: "components/admin-member-info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AdminTeamMemberDetails = () => import('../../src/components/admin/TeamMemberDetails.vue' /* webpackChunkName: "components/admin-team-member-details" */).then(c => wrapFunctional(c.default || c))
export const AdminTeamOverview = () => import('../../src/components/admin/TeamOverview.vue' /* webpackChunkName: "components/admin-team-overview" */).then(c => wrapFunctional(c.default || c))
export const AuthCreatePasswordForm = () => import('../../src/components/auth/CreatePasswordForm.vue' /* webpackChunkName: "components/auth-create-password-form" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordForm = () => import('../../src/components/auth/ForgotPasswordForm.vue' /* webpackChunkName: "components/auth-forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const AuthLoginDialog = () => import('../../src/components/auth/LoginDialog.vue' /* webpackChunkName: "components/auth-login-dialog" */).then(c => wrapFunctional(c.default || c))
export const AuthLoginForm = () => import('../../src/components/auth/LoginForm.vue' /* webpackChunkName: "components/auth-login-form" */).then(c => wrapFunctional(c.default || c))
export const AuthProfileMenu = () => import('../../src/components/auth/ProfileMenu.vue' /* webpackChunkName: "components/auth-profile-menu" */).then(c => wrapFunctional(c.default || c))
export const AuthSignUpView = () => import('../../src/components/auth/SignUpView.vue' /* webpackChunkName: "components/auth-sign-up-view" */).then(c => wrapFunctional(c.default || c))
export const AuthSignupFeatures = () => import('../../src/components/auth/SignupFeatures.vue' /* webpackChunkName: "components/auth-signup-features" */).then(c => wrapFunctional(c.default || c))
export const ChartsAccelerantProgressBar = () => import('../../src/components/charts/AccelerantProgressBar.vue' /* webpackChunkName: "components/charts-accelerant-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartsAccelerantProgressBarBucketed = () => import('../../src/components/charts/AccelerantProgressBarBucketed.vue' /* webpackChunkName: "components/charts-accelerant-progress-bar-bucketed" */).then(c => wrapFunctional(c.default || c))
export const ChartsColumnChart = () => import('../../src/components/charts/ColumnChart.vue' /* webpackChunkName: "components/charts-column-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartsColumnChartD3 = () => import('../../src/components/charts/ColumnChartD3.ts' /* webpackChunkName: "components/charts-column-chart-d3" */).then(c => wrapFunctional(c.default || c))
export const ChartsIndividualSCurveD3 = () => import('../../src/components/charts/IndividualSCurveD3.ts' /* webpackChunkName: "components/charts-individual-s-curve-d3" */).then(c => wrapFunctional(c.default || c))
export const ChartsSCurveChartUtil = () => import('../../src/components/charts/SCurveChartUtil.ts' /* webpackChunkName: "components/charts-s-curve-chart-util" */).then(c => wrapFunctional(c.default || c))
export const ChartsTeamSCurveD3 = () => import('../../src/components/charts/TeamSCurveD3.ts' /* webpackChunkName: "components/charts-team-s-curve-d3" */).then(c => wrapFunctional(c.default || c))
export const DashboardAccelerantResult = () => import('../../src/components/dashboard/AccelerantResult.vue' /* webpackChunkName: "components/dashboard-accelerant-result" */).then(c => wrapFunctional(c.default || c))
export const DashboardBucketDistributionCard = () => import('../../src/components/dashboard/BucketDistributionCard.vue' /* webpackChunkName: "components/dashboard-bucket-distribution-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardBucketExplanation = () => import('../../src/components/dashboard/BucketExplanation.vue' /* webpackChunkName: "components/dashboard-bucket-explanation" */).then(c => wrapFunctional(c.default || c))
export const DashboardCustomAttributesCard = () => import('../../src/components/dashboard/CustomAttributesCard.vue' /* webpackChunkName: "components/dashboard-custom-attributes-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardCustomCategoryWidget = () => import('../../src/components/dashboard/CustomCategoryWidget.vue' /* webpackChunkName: "components/dashboard-custom-category-widget" */).then(c => wrapFunctional(c.default || c))
export const DashboardCard = () => import('../../src/components/dashboard/DashboardCard.vue' /* webpackChunkName: "components/dashboard-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardConstants = () => import('../../src/components/dashboard/DashboardConstants.ts' /* webpackChunkName: "components/dashboard-constants" */).then(c => wrapFunctional(c.default || c))
export const DashboardIndividualDashboard = () => import('../../src/components/dashboard/IndividualDashboard.vue' /* webpackChunkName: "components/dashboard-individual-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DashboardIndividualSCurveChart = () => import('../../src/components/dashboard/IndividualSCurveChart.vue' /* webpackChunkName: "components/dashboard-individual-s-curve-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardManageQuestionAdmin = () => import('../../src/components/dashboard/ManageQuestionAdmin.vue' /* webpackChunkName: "components/dashboard-manage-question-admin" */).then(c => wrapFunctional(c.default || c))
export const DashboardOrganizationSwitcher = () => import('../../src/components/dashboard/OrganizationSwitcher.vue' /* webpackChunkName: "components/dashboard-organization-switcher" */).then(c => wrapFunctional(c.default || c))
export const DashboardOrganizationSwitcherDialog = () => import('../../src/components/dashboard/OrganizationSwitcherDialog.vue' /* webpackChunkName: "components/dashboard-organization-switcher-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardSCurveData = () => import('../../src/components/dashboard/SCurveData.ts' /* webpackChunkName: "components/dashboard-s-curve-data" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminDashboard = () => import('../../src/components/dashboard/SystemAdminDashboard.vue' /* webpackChunkName: "components/dashboard-system-admin-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamAccelerantResults = () => import('../../src/components/dashboard/TeamAccelerantResults.vue' /* webpackChunkName: "components/dashboard-team-accelerant-results" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamDashboard = () => import('../../src/components/dashboard/TeamDashboard.vue' /* webpackChunkName: "components/dashboard-team-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamDirectory = () => import('../../src/components/dashboard/TeamDirectory.vue' /* webpackChunkName: "components/dashboard-team-directory" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamEcosystemResults = () => import('../../src/components/dashboard/TeamEcosystemResults.vue' /* webpackChunkName: "components/dashboard-team-ecosystem-results" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamMemberPicker = () => import('../../src/components/dashboard/TeamMemberPicker.vue' /* webpackChunkName: "components/dashboard-team-member-picker" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamSCurveChart = () => import('../../src/components/dashboard/TeamSCurveChart.vue' /* webpackChunkName: "components/dashboard-team-s-curve-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeamSCurveResults = () => import('../../src/components/dashboard/TeamSCurveResults.vue' /* webpackChunkName: "components/dashboard-team-s-curve-results" */).then(c => wrapFunctional(c.default || c))
export const DashboardTitleTooltip = () => import('../../src/components/dashboard/TitleTooltip.vue' /* webpackChunkName: "components/dashboard-title-tooltip" */).then(c => wrapFunctional(c.default || c))
export const FormsAddPreRegistrationForm = () => import('../../src/components/forms/AddPreRegistrationForm.vue' /* webpackChunkName: "components/forms-add-pre-registration-form" */).then(c => wrapFunctional(c.default || c))
export const FormsFormTypes = () => import('../../src/components/forms/FormTypes.ts' /* webpackChunkName: "components/forms-form-types" */).then(c => wrapFunctional(c.default || c))
export const FormsValidationPill = () => import('../../src/components/forms/ValidationPill.vue' /* webpackChunkName: "components/forms-validation-pill" */).then(c => wrapFunctional(c.default || c))
export const StoriesTypographyTableRow = () => import('../../src/components/stories/TypographyTableRow.vue' /* webpackChunkName: "components/stories-typography-table-row" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminOrganizationPicker = () => import('../../src/components/dashboard/systemAdmin/AdminOrganizationPicker.vue' /* webpackChunkName: "components/dashboard-system-admin-organization-picker" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminAssessmentPicker = () => import('../../src/components/dashboard/systemAdmin/AssessmentPicker.vue' /* webpackChunkName: "components/dashboard-system-admin-assessment-picker" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminBulkPreRegistrationDataTable = () => import('../../src/components/dashboard/systemAdmin/BulkPreRegistrationDataTable.vue' /* webpackChunkName: "components/dashboard-system-admin-bulk-pre-registration-data-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminBulkPreRegistrationForm = () => import('../../src/components/dashboard/systemAdmin/BulkPreRegistrationForm.vue' /* webpackChunkName: "components/dashboard-system-admin-bulk-pre-registration-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminBulkPreRegistrationUploaderDialog = () => import('../../src/components/dashboard/systemAdmin/BulkPreRegistrationUploaderDialog.vue' /* webpackChunkName: "components/dashboard-system-admin-bulk-pre-registration-uploader-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminCreateOrganizationDialog = () => import('../../src/components/dashboard/systemAdmin/CreateOrganizationDialog.vue' /* webpackChunkName: "components/dashboard-system-admin-create-organization-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardSystemAdminCreateOrganizationForm = () => import('../../src/components/dashboard/systemAdmin/CreateOrganizationForm.vue' /* webpackChunkName: "components/dashboard-system-admin-create-organization-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
