import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=64b29452&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=64b29452&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b29452",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/tmp/build_ae374436/src/components/Logo.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VFooter,VRow})
