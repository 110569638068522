import { Context } from '@nuxt/types';
import { getModule } from 'vuex-module-decorators';
import DashboardModule from '@store/dashboard';
import AssessmentStore from '@store/assessment';
import SignupModule from '@store/signup';
import AppModule from '@store/app';
import AnalyticsService from '@client/sevices/AnalyticsService';

export default class NuxtService {
    // eslint-disable-next-line no-use-before-define
    static shared: NuxtService;

    static init(context: Context) {
        NuxtService.shared = new NuxtService(context);
    }

    context: Context;

    constructor(context: Context) {
        this.context = context;
    }

    get $sentry() {
        return this.context.$sentry;
    }

    /**
     * Log the user out and clear all state
     * @return {Promise<void>}
     */
    async logout() {
        await this.context.$auth.logout();
        AnalyticsService.shared.logout();
        const store = this.context.store;
        getModule(DashboardModule, store).resetState();
        getModule(AssessmentStore, store).resetState();
        getModule(SignupModule, store).resetState();
        getModule(AppModule, store).setNavigationDrawerOpen(false);
        this.context.redirect('/');
    }
}
