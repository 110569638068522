export function isBlank(input: string | null | undefined): input is null {
    return !input || input.trim().length === 0;
}

export function isNotBlank(input: string | null | undefined): input is string {
    return !!input && input.trim().length > 0;
}

export function isDefined<T>(input: T | null | undefined): input is T {
    return input !== null && input !== undefined;
}

export function isNumber(input: number | null | undefined): input is number {
    return input !== null && input !== undefined && !isNaN(input);
}

export function isString(input: string | null | undefined): input is string {
    return isDefined(input);
}
