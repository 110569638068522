/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import SignupModule from '@store/signup';
import AppModule from '@store/app';
import AssessmentStore from '@store/assessment';
import DashboardModule from '@store/dashboard';
import TeamAdminModule from '@store/teamAdmin';
import SystemAdminModule from '@store/systemAdmin';

let rootStore: Store<any>;
let signupModule: SignupModule;
let appModule: AppModule;
let assessmentModule: AssessmentStore;
let dashboardModule: DashboardModule;
let teamAdmin: TeamAdminModule;
let systemAdmin: SystemAdminModule;

function initializeStores(store: Store<any>): void {
    rootStore = store;
    appModule = getModule(AppModule, store);
    signupModule = getModule(SignupModule, store);
    assessmentModule = getModule(AssessmentStore, store);
    dashboardModule = getModule(DashboardModule, store);
    teamAdmin = getModule(TeamAdminModule, store);
    systemAdmin = getModule(SystemAdminModule, store);
}

export {
    initializeStores,
    appModule,
    assessmentModule,
    dashboardModule,
    rootStore,
    signupModule,
    systemAdmin,
    teamAdmin,
};
