import { CreatePreRegistrationParams, PreRegistration } from '@shared/models/PreRegistration';
import User from '@shared/models/User';
import { TeamMember } from '@shared/api/DashboardTypes';

export enum InviteUserStatus {
    ERROR = 'ERROR',
    NO_USER_FOUND = 'NO_USER_FOUND',
    ADDED_EXISTING_USER = 'ADDED_EXISTING_USER',
    ADDED_PRE_REGISTRATION = 'ADDED_PRE_REGISTRATION',
}

export interface InviteUserResult {
    status: InviteUserStatus;
    success: boolean;
    preRegistration?: PreRegistration | null;
    error?: unknown | null;
    user?: User | null;
    teamMember?: TeamMember;
    item?: CreatePreRegistrationParams;
}

export type InvalidBulkItem = {
    error: string;
    item: CreatePreRegistrationParams;
};

export interface BulkInviteResult {
    invalid?: InvalidBulkItem[];
    results?: InviteUserResult[];
}
