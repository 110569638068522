import {
    config as vueGtagConfig,
    event as vueGtagEvent,
    GtagConfig,
    GtagEvent as GtagEventSignature,
    GtagSet,
    set as vueGtagSet,
} from 'vue-gtag';
import User from '@shared/models/User';
import { SCurveLocation } from '@shared/api/DashboardTypes';

export enum AnalyticsEvent {
    scurveLocationSelected = 'scurve_location_selected',
    scurveLocationCleared = 'scurve_location_cleared',
    teamMemberSelected = 'team_member_selected',
    teamMemberCleared = 'team_member_cleared',
    emailVerificationRequest = 'email_verification_request',
    emailVerificationSent = 'email_verification_sent',
    resendEmailVerificationRequest = 'resend_email_verification_request',
    resetPasswordRequest = 'reset_password_request',
    resetPasswordSent = 'reset_password_sent',
    resetPasswordEmailNotFound = 'reset_password_email_not_found',
    emailNotPreRegistered = 'email_not_pre_registered',
    assessmentNextQuestion = 'assessment_next_question',
    assessmentPreviousQuestion = 'assessment_previous_question',
    assessmentStarted = 'assessment_started',
    assessmentCompleted = 'assessment_completed',
}

/**
 * A service class to manage sending analytics events to various analytics services
 * Current analytics providers are:
 *    - Google Analytics vis gtag.js
 */
export default class AnalyticsService {
    static shared = new AnalyticsService();

    enabled: boolean;

    constructor() {
        this.enabled = process.client;
    }

    /**
     * Send a google analytics event
     *
     * @param {Gtag.EventNames | string} action
     * @param {Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined} params
     */
    gtagEvent: GtagEventSignature = (action, params) => {
        try {
            if (this.enabled) {
                console.debug('Gtag.event', action, params);
                vueGtagEvent(action, params);
            } else {
                console.debug('[Analytics disabled] GTag.event', action, params);
            }
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Set properties that will be sent with every analytics event to Google analytics
     * @param {Gtag.CustomParams} params
     */
    gtagSet: GtagSet = (params) => {
        try {
            if (this.enabled) {
                console.debug('Gtag.set', params);
                vueGtagSet(params);
            } else {
                console.debug('[Analytics disabled] Gtag.set', params);
            }
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Set config values for gtag
     * @param {Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined} params
     */
    gtagConfig: GtagConfig = (params) => {
        try {
            if (this.enabled) {
                console.debug('Gtag.config', params);
                vueGtagConfig(params);
            } else {
                console.debug('[Analytics disabled] Gtag.config', params);
            }
        } catch (error) {
            console.error(error);
        }
    };

    setUser(user?: User | null) {
        this.gtagSet({
            user_properties: {
                organization_id: user?.organizationId ?? null,
                organization_name: user?.organizationName ?? null,
                roles: user?.roles ?? [],
                user_id: user?.id ?? null,
            },
        });
        this.gtagConfig({ user_id: user?.id ?? null });
    }

    /**
     * Notify analytics that a user has logged in.
     * Sets the current user on the analytics session.
     *
     * @param {User} user
     * @return {Promise<void>}
     */
    loginEvent(user: User) {
        this.setUser(user);
        this.gtagEvent('login');
    }

    /**
     * Notify analytics that a new user has signed up.
     * Sets the current user on the analytics session.
     *
     * @param {User} user
     * @return {Promise<void>}
     */
    signupEvent(user: User) {
        this.setUser(user);
        this.gtagEvent('sign_up');
    }

    /**
     * Notify analytics that a user has logged out.
     * This action will clear the current user on the current analytics session
     * @return {Promise<void>}
     */
    logout() {
        this.gtagEvent('logout');
        this.setUser(null);
    }

    /**
     * Send an event to analytics
     *
     * @param {AnalyticsEvent} event
     * @param {object} [params] - optional event params
     */
    sendEvent(event: AnalyticsEvent, params?: object) {
        this.gtagEvent(event, params);
    }

    /**
     * Notify analytics that the scurve location has changed;
     * @param {SCurveLocation | null} location
     */
    scurveLocationSelected(location: SCurveLocation | null) {
        if (location) {
            this.gtagEvent(AnalyticsEvent.scurveLocationSelected, {
                ...location,
            });
        } else {
            this.gtagEvent(AnalyticsEvent.scurveLocationCleared);
        }
    }

    /**
     * Notify analytics that the an individual has been selected;
     */
    teamMemberSelected() {
        this.gtagEvent(AnalyticsEvent.teamMemberSelected);
    }

    /**
     * Notify analytics that the selected team member has been cleared;
     */
    teamMemberCleared() {
        this.gtagEvent(AnalyticsEvent.teamMemberCleared);
    }
}
