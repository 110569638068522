import { SCurveSection } from '@shared/models/assessment/UserAssessment';

export enum AggregationCategoryGroup {
    CORPORATE_CULTURE = 'CORPORATE_CULTURE',
    EDUCATIONAL_CULTURE = 'EDUCATIONAL_CULTURE',
    ACCELERANT = 'ACCELERANT',
    S_CURVE = 'S_CURVE',
}

export enum QuestionCategory {
    ACCELERANT_RIGHT_RISKS = 'ACCELERANT_RIGHT_RISKS',
    ACCELERANT_EMBRACING_CONSTRAINTS = 'ACCELERANT_EMBRACING_CONSTRAINTS',
    ACCELERANT_STEP_DOWN_SIDEWAYS = 'ACCELERANT_STEP_DOWN_SIDEWAYS',
    ACCELERANT_GIVE_FAILURE_ITS_DUE = 'ACCELERANT_GIVE_FAILURE_ITS_DUE',
    ACCELERANT_DRIVEN_BY_DISCOVERY = 'ACCELERANT_DRIVEN_BY_DISCOVERY',
    ACCELERANT_DISTINCTIVE_STRENGTHS = 'ACCELERANT_DISTINCTIVE_STRENGTHS',
    ACCELERANT_EXAMINE_EXPECTATIONS = 'ACCELERANT_EXAMINE_EXPECTATIONS',
    ECOSYSTEM_OVERCOMING = 'ECOSYSTEM_OVERCOMING',
    ECOSYSTEM_NURTURING = 'ECOSYSTEM_NURTURING',
    ECOSYSTEM_CONNECTIVE = 'ECOSYSTEM_CONNECTIVE',
    ECOSYSTEM_CONDUCIVE = 'ECOSYSTEM_CONDUCIVE',
    LEARNING_CONTEXT = 'LEARNING_CONTEXT',
    S_CURVE_LOCATOR = 'S_CURVE_LOCATOR',
    S_CURVE_LAUNCH = 'S_CURVE_LAUNCH',
    S_CURVE_SWEET_SPOT = 'S_CURVE_SWEET_SPOT',
    S_CURVE_MASTERY = 'S_CURVE_MASTER',
}

export type SCurveSectionCategory =
    | QuestionCategory.S_CURVE_LAUNCH
    | QuestionCategory.S_CURVE_SWEET_SPOT
    | QuestionCategory.S_CURVE_MASTERY;

export const SCurveSectionCategories = [
    QuestionCategory.S_CURVE_LAUNCH,
    QuestionCategory.S_CURVE_SWEET_SPOT,
    QuestionCategory.S_CURVE_MASTERY,
];

export function getSCurveSectionCategory(section: SCurveSection): SCurveSectionCategory {
    switch (section) {
        case SCurveSection.LAUNCH:
            return QuestionCategory.S_CURVE_LAUNCH;
        case SCurveSection.SWEET_SPOT:
            return QuestionCategory.S_CURVE_SWEET_SPOT;
        case SCurveSection.MASTERY:
            return QuestionCategory.S_CURVE_MASTERY;
    }
}

export function isSCurveSectionCategory(input: QuestionCategory): input is SCurveSectionCategory {
    return SCurveSectionCategories.includes(input);
}

export type AccelerantCategory =
    | QuestionCategory.ACCELERANT_RIGHT_RISKS
    | QuestionCategory.ACCELERANT_EMBRACING_CONSTRAINTS
    | QuestionCategory.ACCELERANT_EXAMINE_EXPECTATIONS
    | QuestionCategory.ACCELERANT_STEP_DOWN_SIDEWAYS
    | QuestionCategory.ACCELERANT_GIVE_FAILURE_ITS_DUE
    | QuestionCategory.ACCELERANT_DRIVEN_BY_DISCOVERY
    | QuestionCategory.ACCELERANT_DISTINCTIVE_STRENGTHS;

export function isAccelerantCategory(input: QuestionCategory | string): input is AccelerantCategory {
    return CategoryGroup.ACCELERANT.includes(input as QuestionCategory);
}

export type CorporateEcosystemCategory =
    | QuestionCategory.ECOSYSTEM_OVERCOMING
    | QuestionCategory.ECOSYSTEM_NURTURING
    | QuestionCategory.ECOSYSTEM_CONNECTIVE
    | QuestionCategory.ECOSYSTEM_CONDUCIVE;

export type EducationEcosystemCategory = QuestionCategory.LEARNING_CONTEXT;

export type EcosystemCategory = CorporateEcosystemCategory; // TODO: Add Education Ecosystem

export const CategoryGroup: Record<AggregationCategoryGroup, QuestionCategory[]> = {
    [AggregationCategoryGroup.ACCELERANT]: [
        QuestionCategory.ACCELERANT_RIGHT_RISKS,
        QuestionCategory.ACCELERANT_EMBRACING_CONSTRAINTS,
        QuestionCategory.ACCELERANT_EXAMINE_EXPECTATIONS,
        QuestionCategory.ACCELERANT_STEP_DOWN_SIDEWAYS,
        QuestionCategory.ACCELERANT_GIVE_FAILURE_ITS_DUE,
        QuestionCategory.ACCELERANT_DRIVEN_BY_DISCOVERY,
        QuestionCategory.ACCELERANT_DISTINCTIVE_STRENGTHS,
    ],
    [AggregationCategoryGroup.CORPORATE_CULTURE]: [
        QuestionCategory.ECOSYSTEM_OVERCOMING,
        QuestionCategory.ECOSYSTEM_NURTURING,
        QuestionCategory.ECOSYSTEM_CONNECTIVE,
        QuestionCategory.ECOSYSTEM_CONDUCIVE,
    ],
    [AggregationCategoryGroup.EDUCATIONAL_CULTURE]: [QuestionCategory.LEARNING_CONTEXT],
    [AggregationCategoryGroup.S_CURVE]: [QuestionCategory.S_CURVE_LOCATOR],
};
