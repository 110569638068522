import { ValidationError } from 'yup';
import { isDefined, isNotBlank } from '@shared/util/TypeGuards';

export enum ErrorCode {
    UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
    VALIDATION_FAILED = 'VALIDATION_FAILED',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
    ASSESSMENT_NOT_FOUND = 'ASSESSMENT_NOT_FOUND',
    NOT_FOUND = 'NOT_FOUND',
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
    UNABLE_TO_CREATE_TOKEN = 'UNABLE_TO_CREATE_TOKEN',
    UNABLE_TO_SEND_EMAIL = 'UNABLE_TO_SEND_EMAIL',
    PROVIDER_NOT_SUPPORTED = 'PROVIDER_NOT_SUPPORTED',
    INVALID_TOKEN = 'INVALID_TOKEN',
    INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
}

export interface ApiError {
    error: {
        message: string;
        code?: ErrorCode;
        validationError?: ValidationError;
    };
    status?: number;
}

export interface ApiErrorResponse extends ApiError {
    status?: number;
}

export function isApiError(input: object | null | undefined): input is ApiError {
    if (!isDefined(input)) {
        return false;
    }
    const r = input as ApiError;
    return !!r.error && (isNotBlank(r.error.message) || isNotBlank(r.error.code));
}
