import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { QuestionCategory } from '@shared/models/assessment/QuestionCategory';
import { SCurveSection } from '@shared/models/assessment/UserAssessment';
import {
    AccelerantCategoryCopyMap,
    AccelerantInsightsSectionCopy,
    AssessmentCopy,
    DashboardSectionCopy,
    EcosystemCategoryCopyMap,
    PageMap,
    SCurveCopy,
    DashboardCopy,
    DashboardCopyType,
    SignupCopy,
} from '@shared/constants/CopyTypes';

interface LocalizedCopy {
    brandName: string;
    pages: PageMap;
    scurve: SCurveCopy;
    assessment: AssessmentCopy;
    accelerants: AccelerantCategoryCopyMap & DashboardSectionCopy & AccelerantInsightsSectionCopy;
    ecosystem: EcosystemCategoryCopyMap & DashboardSectionCopy;
    signup: SignupCopy;
    dashboard: DashboardCopyType;

    [key: string]: any;
}

const copy: LocalizedCopy = {
    brandName: 'S Curve Insight',
    noResults: 'There are no results.',
    assessment: {
        processingResults: 'Processing your results',
    },
    dashboard: DashboardCopy,
    signup: {
        sendingEmail: 'Sending email',
        pleaseVerifyYourAccount: 'Please verify your account',
        pleaseEnterYourEmail: 'Please enter your email address',
        pleaseEnterYourPassword: 'Please enter your password',
        sendResetLink: 'Send reset link',
        welcomeBackExclamation: 'Welcome back!',
        tokenUsedOrInvalid: 'It looks like this this link has already been used or is invalid. Please try again.',
    },
    pages: {
        privacy_policy: {
            title: 'Privacy Policy',
        },
        terms_and_conditions: {
            title: 'Terms & Conditions',
        },
    },
    accelerantsInsights: 'Accelerants Insights',
    scurve: {
        placement: {
            0: { title: 'Low' },
            1: { title: 'High' },
        },
        section: {
            [SCurveSection.LAUNCH]: {
                title: 'Launch',
                tooltip:
                    'The launch point can feel difficult and slow: output is low and the ' +
                    'learning curve can be intimidating, but the foundation for an exciting ' +
                    'future is being set.',
                descriptionLong:
                    'The excitement associated with starting a new role is often offset by ' +
                    'various negative emotions, like feeling intimidated or frustrated by the slow pace of growth. ' +
                    'Leaders that can identify the team members in this phase are ' +
                    'empowered to help normalize this mix of emotions and provide the ' +
                    'extra resources that are needed for growth.  ' +
                    'Similarly, identifying the people that are stuck in this phase too ' +
                    'long can prompt even deeper support - or help transition to a new role.',
            },
            [SCurveSection.SWEET_SPOT]: {
                title: 'Sweet-Spot',
                tooltip:
                    'The sweet-spot is exciting and fast: you experience higher ' +
                    'output but enough new opportunity to stay learning and motivated.',
                descriptionLong:
                    'Team members in this phase are both productive and energized by additional opportunity - ' +
                    'and provide the majority of productivity for the team. ' +
                    'Identifying team members in this phase helps leaders focus resources ' +
                    'and optimize the flow of new team members into this phase as veterans move out.',
            },
            [SCurveSection.MASTERY]: {
                title: 'Mastery',
                tooltip:
                    'Mastery is steady but possibly boring: there is still potential ' +
                    'for reasonable output, but it is harder to stay motivated when ' +
                    'most of the learning has happened.',
                descriptionLong:
                    'Identifying team members in this phase is critical for leaders, ' +
                    "because while they might often still perform at 'sweet spot' levels, " +
                    'they are also at risk of getting bored and leaving. By knowing who your ' +
                    "'masters' are, you can give new challenges, encourage them to mentor others, " +
                    'or help them find a new role that will better serve the needs of the overall organization.',
            },
        },
    },
    accelerants: {
        sectionTitle: 'Accelerants',
        insightsTitle: 'Accelerants Insights',
        [QuestionCategory.ACCELERANT_RIGHT_RISKS]: {
            title: 'Take the Right Risks',
            tooltip: 'Growth requires risk, and learning to take the right risks will accelerate growth.',
        },
        [QuestionCategory.ACCELERANT_EMBRACING_CONSTRAINTS]: {
            title: 'Embrace constraints',
            tooltip:
                'Dreaming of a world without constraints misses a powerful truth: ' +
                'constraints provide structure that we can leverage toward our growth.',
        },
        [QuestionCategory.ACCELERANT_EXAMINE_EXPECTATIONS]: {
            title: 'Examine Expectations',
            tooltip:
                'Expectations - like gravity - exert a steady and significant force on our goals and behavior. ' +
                'Thoughtful examination of this influence helps us sort which expectations are promoting healthy growth and which are hindering our growth.',
        },
        [QuestionCategory.ACCELERANT_STEP_DOWN_SIDEWAYS]: {
            title: 'Step back to grow',
            tooltip: 'Stepping sideways, back or down, can be a slingshot toward our growth.',
        },
        [QuestionCategory.ACCELERANT_GIVE_FAILURE_ITS_DUE]: {
            title: 'Leverage failure',
            tooltip:
                'Failure can be a powerful teacher - a constraint that becomes a tool ' +
                'of creation - if we can check our ego and listen to learn.',
        },
        [QuestionCategory.ACCELERANT_DRIVEN_BY_DISCOVERY]: {
            title: 'Be discovery driven',
            tooltip: 'On the other side of uncertainty and vulnerability are the rich rewards of discovery.',
        },
        [QuestionCategory.ACCELERANT_DISTINCTIVE_STRENGTHS]: {
            title: 'Play to your distinctive strengths',
            tooltip:
                'Growth accelerates when the unique needs of the moment are matched with the distinctive strengths of the individual.',
        },
    },
    ecosystem: {
        sectionTitle: 'Ecosystem',
        [QuestionCategory.ECOSYSTEM_CONNECTIVE]: {
            title: 'Connected',
            tooltip: 'Vibrant connections are the difference between isolated individuals and a powerful team.',
        },
        [QuestionCategory.ECOSYSTEM_NURTURING]: {
            title: 'Nurturing',
            tooltip:
                'Management relationships characterized by openness and investment in growth will nurture health in individuals and teams.',
        },
        [QuestionCategory.ECOSYSTEM_OVERCOMING]: {
            title: 'Resilience',
            tooltip: 'Successful teams have the spirit and resources to overcome the setbacks that inevitably come.',
        },
        [QuestionCategory.ECOSYSTEM_CONDUCIVE]: {
            title: 'Conducive',
            tooltip: 'Teams only thrive and grow in a context with the right physical and emotional resources.',
        },
    },
};

export default copy;

export function getPageTitle(pageName: string, $config: NuxtRuntimeConfig) {
    return `${$config.titlePrefix ?? ''} ${pageName} | ${copy.brandName}`.trim();
}
