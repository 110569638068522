import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Location } from 'vue-router';
import { Severity } from '@sentry/types';
import { Question, QuestionChoiceType } from '@shared/models/assessment/Question';
import { isDefined, isNotBlank, isNumber } from '@shared/util/TypeGuards';
import { Assessment } from '@shared/models/assessment/Assessment';
import ApiService from '@client/sevices/ApiService';
import { UserAssessment } from '@shared/models/assessment/UserAssessment';
import { UserAssessmentAggregation } from '@shared/api/UserAssessmentControllerTypes';
import {
    AnswerMap,
    AnyAnswerType,
    QuestionResponseType,
    UserAssessmentResponse,
} from '@shared/models/assessment/UserAssessmentResponse';
import NuxtService from '@client/sevices/NuxtService';
import { RouteName } from '@shared/routing/PageRoutes';
import { getSCurveSectionCategory, isSCurveSectionCategory } from '@shared/models/assessment/QuestionCategory';
import AssessmentConfig, { DEFAULT_ASSESSMENT_CONFIG } from '@shared/models/assessment/AssessmentConfig';
import { getScurveLocatorScore, getSCurveResults } from '@shared/util/AssessmentUtil';
import SCurveResult from '@shared/models/assessment/SCurveResult';
import AnalyticsService, { AnalyticsEvent } from '@client/sevices/AnalyticsService';

@Module({
    name: 'assessment',
    stateFactory: true,
    namespaced: true,
})
export default class AssessmentStore extends VuexModule {
    questionIndex: number = 0;
    creatingUserAssessment: boolean = false;
    loadingUserAssessment: boolean = false;
    assessment: Assessment | null = null;
    assessmentConfig: AssessmentConfig = DEFAULT_ASSESSMENT_CONFIG();
    _questions: Question[] = [];
    userAssessment: UserAssessment | null = null;
    continueError: string | null = null;
    /**
     * Answers by question Id
     * @type {Record<string, string | number>}
     */
    answers: AnswerMap = {};
    isProcessingResults: boolean = false;

    @Mutation
    resetState() {
        this.questionIndex = 0;
        this.creatingUserAssessment = false;
        this.loadingUserAssessment = false;
        this.assessment = null;
        this.assessmentConfig = DEFAULT_ASSESSMENT_CONFIG();
        this._questions = [];
        this.userAssessment = null;
        this.continueError = null;
        this.answers = {};
        this.isProcessingResults = false;
    }

    @Mutation
    resetAssessment() {
        this.answers = {};
        this._questions = [];
        this.userAssessment = null;
        // this.
    }

    get questions(): Question[] {
        const currentSection = this.scurveResult.section;
        const sectionCategory = getSCurveSectionCategory(currentSection);
        return this._questions.filter((q) => {
            return (
                q.categories?.every((category) => {
                    return !isSCurveSectionCategory(category) || sectionCategory === category;
                }) ?? true
            );
        });
    }

    get scurveLocatorScore(): number {
        return getScurveLocatorScore(this._questions, this.answers);
    }

    get scurveResult(): SCurveResult {
        return getSCurveResults(this._questions, this.answers, this.assessmentConfig);
    }

    get resultsLocation(): Location | null {
        if (this.isCompleted) {
            return {
                name: RouteName.dashboardYourResults,
            };
        }
        return null;
    }

    get assessmentLocation(): Location | null {
        const userAssessmentId = this.userAssessment?.userAssessmentId;
        if (!userAssessmentId) {
            return null;
        }

        return {
            name: RouteName.userAssessment,
            params: {
                userAssessmentId: `${userAssessmentId}`,
            },
            query: {
                page: `${(this.firstUnansweredQuestionIndex ?? 0) + 1}`,
            },
        };
    }

    get isCompleted(): boolean {
        return isDefined(this.userAssessment?.completedAt);
    }

    get currentAnswer(): AnyAnswerType | null {
        const questionId = this.currentQuestion?.questionId;
        if (isDefined(questionId)) {
            return this.answers[questionId!];
        }
        return null;
    }

    get totalQuestions(): number {
        return this.questions.length;
    }

    get currentQuestion(): Question | null {
        return this.questions[this.questionIndex] ?? null;
    }

    get hasNext(): boolean {
        return this.questionIndex < this.totalQuestions - 1;
    }

    get hasPrevious(): boolean {
        return this.questionIndex > 0;
    }

    get isLastQuestion() {
        return this.questionIndex === this.totalQuestions - 1;
    }

    get nextEnabled(): boolean {
        const question = this.currentQuestion;
        const isNoChoice = this.currentQuestion?.choiceType === QuestionChoiceType.NONE;

        if (isNoChoice) {
            return true;
        }

        let hasAnswer: boolean;
        if (question?.choiceType === QuestionChoiceType.LONG_TEXT) {
            hasAnswer = isNotBlank(this.currentAnswer?.textValue);
        } else {
            hasAnswer = isDefined(this.currentAnswer);
        }

        return (this.hasNext || this.isLastQuestion) && hasAnswer;
    }

    /**
     * Using the answers and the questions in the store,
     * determine greatest index of the questions that have an answer.
     * @return {number | null} - the largest answered question index
     *  or null if no questions have been answered
     */
    get firstUnansweredQuestionIndex(): number | null {
        const questions = this.questions;
        const answers = this.answers;

        const index: number = questions.findIndex((q) => {
            const isAnswerable = q.choiceType !== QuestionChoiceType.NONE;
            return isAnswerable && !Object.prototype.hasOwnProperty.call(answers, q.questionId);
        });
        if (questions.length === Object.keys(answers).length) {
            return questions.length - 1;
        }
        return index >= 0 ? index : null;
    }

    @Mutation
    setContinueError(error: string | null) {
        this.continueError = error;
    }

    @Mutation
    setCreatingUserAssessment(creating: boolean) {
        this.creatingUserAssessment = creating;
    }

    static transformResponsesToAnswers(responses?: UserAssessmentResponse[] | null | undefined): AnswerMap {
        if (!responses) {
            return {};
        }
        const answerMap: AnswerMap = {};
        return responses.reduce((answers, response) => {
            answers[response.questionId] = response;
            return answers;
        }, answerMap);
    }

    @Mutation
    setUserAssessmentAggregation(payload?: UserAssessmentAggregation | null) {
        const {
            responses = [],
            questions = [],
            assessmentConfig,
            assessment = null,
            userAssessment = null,
        } = payload ?? {};
        this._questions = questions ?? [];
        this.assessment = assessment ?? null;
        this.answers = AssessmentStore.transformResponsesToAnswers(responses);
        this.userAssessment = userAssessment;
        this.assessmentConfig = assessmentConfig ?? DEFAULT_ASSESSMENT_CONFIG();
    }

    @Mutation
    setLoadingUserAssessment(creating: boolean) {
        this.loadingUserAssessment = creating;
    }

    @Mutation
    setQuestions(questions: Question[]) {
        this._questions = questions;
    }

    @Mutation
    setAssessment(assessment: Assessment | null) {
        this.assessment = assessment;
    }

    @Mutation
    setAnswer(params: { answer: QuestionResponseType; questionId: number }) {
        this.continueError = null;
        const { answer, questionId } = params;
        const answers = { ...this.answers };
        answers[questionId] = answer;
        this.answers = answers;
    }

    @Mutation
    setAnswerMap(answers: AnswerMap) {
        this.answers = answers;
    }

    @Mutation
    setQuestionIndex(index: number) {
        this.questionIndex = Math.max(Math.min(this._questions.length - 1, index), 0);
    }

    @Mutation
    setCompleted(date: number) {
        if (this.userAssessment) {
            this.userAssessment.completedAt = date;
        }
    }

    @Mutation
    setProcessingResults(processing: boolean) {
        this.isProcessingResults = processing;
    }

    @Action
    async syncRouter() {
        if (this.resultsLocation) {
            await NuxtService.shared.context.redirect(this.resultsLocation);
        } else if (this.assessmentLocation) {
            await NuxtService.shared.context.redirect(this.assessmentLocation);
        } else {
            console.warn('assessment.syncRouter: no router location to sync');
        }
    }

    @Action
    async nextQuestion() {
        if (!this.nextEnabled) {
            if (this.currentQuestion?.choiceType === QuestionChoiceType.LONG_TEXT) {
                this.setContinueError('Please enter a response');
            } else {
                this.setContinueError('Please select a value');
            }

            return;
        }
        this.setContinueError(null);
        const questionId = this.currentQuestion?.questionId;
        const doSave = this.currentQuestion?.choiceType !== QuestionChoiceType.NONE;

        this.setQuestionIndex(this.questionIndex + 1);
        AnalyticsService.shared.sendEvent(AnalyticsEvent.assessmentNextQuestion, {
            question_index: this.questionIndex,
            question_id: questionId ?? null,
        });
        await this.syncRouter();
        if (doSave) {
            await this.saveUserAssessment({ questionId });
        }
    }

    @Action
    async previousQuestion() {
        this.setContinueError(null);
        this.setQuestionIndex(this.questionIndex - 1);
        AnalyticsService.shared.sendEvent(AnalyticsEvent.assessmentPreviousQuestion, {
            question_index: this.questionIndex,
            question_id: this.currentQuestion?.questionId ?? null,
        });
        await this.syncRouter();
    }

    @Action
    async redirectToResults(): Promise<boolean> {
        const location = this.resultsLocation;
        if (location) {
            console.log('using NuxtService to redirect to ', location);
            await NuxtService.shared.context.redirect({
                name: RouteName.dashboardYourResults,
            });
        } else {
            NuxtService.shared.$sentry.captureMessage(
                '[store/assessment.ts] Redirect to results: No results location found!',
                Severity.Error
            );
        }
        return !!location;
    }

    @Action
    async nextChoice() {
        const questionId = this.currentQuestion?.questionId;
        if (!questionId) {
            return;
        }
        const currentValue =
            this.currentAnswer?.numberValue ?? this.currentAnswer?.dateValue ?? this.currentAnswer?.textValue;

        const choices = this.currentQuestion?.choices;
        if (!choices) {
            console.warn('assessmentModule: there are no choices to choose from for the current question');
            return;
        }

        const choiceIndex = choices.findIndex((c) => c.value === currentValue);
        const nextAnswer = choices[(choiceIndex ?? 0) + 1];

        if (isDefined(nextAnswer) && isDefined(nextAnswer.value) && isNumber(nextAnswer.value)) {
            this.setAnswer({
                questionId,
                answer: { numberValue: nextAnswer.value },
            });
        }
    }

    @Action
    async previousChoice() {
        const questionId = this.currentQuestion?.questionId;
        if (!questionId) {
            console.warn('there is no current question, can not set the next choice');
            return;
        }
        const currentValue =
            this.currentAnswer?.numberValue ?? this.currentAnswer?.dateValue ?? this.currentAnswer?.textValue;

        const choices = this.currentQuestion?.choices;
        if (!choices) {
            console.warn('there are no choices to choose from');
            return;
        }

        const choiceIndex = choices.findIndex((c) => c.value === currentValue);
        const previousAnswer = choices[(choiceIndex ?? 1) - 1];

        if (isDefined(previousAnswer) && isDefined(previousAnswer.value) && isNumber(previousAnswer.value)) {
            this.setAnswer({
                questionId,
                answer: { numberValue: previousAnswer.value },
            });
        }
    }

    @Action
    async finishAssessment() {
        this.setProcessingResults(true);
        this.setCompleted(Date.now());
        NuxtService.shared.$sentry.captureMessage('[store/assessment.ts] Completing the assessment');
        const questionId = this.currentQuestion?.questionId;
        await this.saveUserAssessment({ questionId });
        NuxtService.shared.$sentry.captureMessage(
            '[store/assessment.ts] Complete assessment saved and timeout finished. Redirecting to results'
        );
        AnalyticsService.shared.sendEvent(AnalyticsEvent.assessmentCompleted);
        // await NuxtService.shared.context.redirect({
        //   name: RouteName.dashboardYourResults,
        // });
        // const redirecting = await this.redirectToResults();
        // if (!redirecting) {
        //   NuxtService.shared.$sentry.captureMessage(
        //     '[store/assessment.ts] Unable to redirect to results! Sending to dashboard.'
        //   );
        //   await NuxtService.shared.context.redirect({
        //     name: RouteName.dashboardYourResults,
        //   });
        // }
        // console.log(
        //   'redirected to results page. Setting processing results to false'
        // );
    }

    @Action
    async saveUserAssessment(options?: { questionId?: number }) {
        const questionId = options?.questionId;
        // get answers, save them to API
        const userAssessmentId = this.userAssessment?.userAssessmentId;
        if (!userAssessmentId) {
            return;
        }
        const completed = isDefined(this.userAssessment?.completedAt);

        const answers = questionId ? { [questionId]: this.answers[questionId] } : this.answers;

        await ApiService.shared.saveUserAssessmentAnswers({
            answers,
            userAssessmentId,
            completed,
        });
        console.log('Save assessment completed');
    }

    /**
     * Load a user assessment by userAssessmentId.
     * If the assessment is already loaded in the store,
     * it will not be fetched from the server.
     * To ensure the assessment is always fetched from the server,
     * pass the `force` option as `true`.
     * @param {{userAssessmentId: number, force?: boolean}} params
     * @return {Promise<void>}
     */
    @Action
    async loadUserAssessmentById(params: { userAssessmentId: number; force?: boolean }) {
        const { userAssessmentId, force = false } = params;

        if (this.userAssessment?.userAssessmentId === userAssessmentId && !force) {
            console.info('User assessment is already loaded, not re-retching it unless the force flag is passed');
            // await this.syncRouter();
            return;
        }
        this.setLoadingUserAssessment(true);
        let aggregation: UserAssessmentAggregation | null = null;
        if (userAssessmentId) {
            aggregation = await ApiService.shared.getUserAssessmentAggregation({
                userAssessmentId,
            });
        }
        this.setUserAssessmentAggregation(aggregation);
        this.setQuestionIndex(this.firstUnansweredQuestionIndex ?? 0);
        this.setLoadingUserAssessment(false);
    }

    @Action
    async createNewAssessment(): Promise<UserAssessmentAggregation | null> {
        this.setProcessingResults(false);
        this.setCreatingUserAssessment(true);
        this.setQuestionIndex(0);
        this.resetAssessment();
        const createResult = await ApiService.shared.createUserAssessment();
        AnalyticsService.shared.sendEvent(AnalyticsEvent.assessmentStarted);
        this.setUserAssessmentAggregation(createResult);
        this.setCreatingUserAssessment(false);
        await this.syncRouter();
        return createResult ?? null;
    }

    @Action
    async loadCurrentAssessment(): Promise<void> {
        if (isDefined(this.userAssessment)) {
            return;
        }
        this.setLoadingUserAssessment(true);
        const userAssessmentAggregation = await ApiService.shared.getCurrentUserAssessment();
        this.setUserAssessmentAggregation(userAssessmentAggregation);
        this.setLoadingUserAssessment(false);
        const index = this.firstUnansweredQuestionIndex ?? 0;
        this.setQuestionIndex(index);
    }

    @Action
    async continueLatestAssessmentIfExists(): Promise<void> {
        await this.loadCurrentAssessment();
        this.setProcessingResults(false);
        const location = this.assessmentLocation;
        if (location) {
            await NuxtService.shared.context.redirect(location);
        }
    }

    /**
     * If the user has no assessment or an unfinished assessment,
     * redirect them to the assessment, creating a new one if necessary.
     *
     * If the user has a completed assessment, redirect to the provided route.
     * @params {Location} [params.routeIfCompleted=Dashboard]  Location to send the user if the assessment is completed.
     * @return {Promise<void>}
     */
    @Action
    async redirectToUnfinishedOrNewAssessment(params?: { locationIfCompleted?: Location }) {
        await this.loadCurrentAssessment();
        this.setProcessingResults(false);
        if (this.isCompleted) {
            const fallback = params?.locationIfCompleted ?? {
                name: RouteName.dashboard,
            };
            await NuxtService.shared.context.redirect(fallback);
            return;
        }
        let location = this.assessmentLocation;
        if (!location) {
            await this.createNewAssessment();
            location = this.assessmentLocation;
        }
        if (location) {
            await NuxtService.shared.context.redirect(location);
            return;
        }
        console.error(
            new Error('Failed to send user to current or new assessment ' + '- no assessment location exists')
        );
    }
}
