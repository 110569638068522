import { Context, Plugin } from '@nuxt/types';
import Vue from 'vue';
import VueGtag, { set } from 'vue-gtag';
import User from '@shared/models/User';

/**
 * Set up the GTag integration as a plugin on the Vue instance.
 *
 * Internal traffic can be filtered from reporting gby passing the params
 * {traffic_type: 'internal'}
 *
 * @param {Context} context
 * @constructor
 */
const GTagPlugin: Plugin = (context: Context) => {
    const { $config, $auth } = context;
    const measurementId = $config.gtagMeasurementId;
    const currentUser: User | null = $auth.user;
    console.log(`setting up google analytics with measurementId=${measurementId} | userId = ${currentUser?.id}`);

    Vue.use(VueGtag, {
        config: {
            id: measurementId,
            params: {
                user_id: currentUser?.id ?? null,
            },
            onBeforeTrack() {
                console.log('before tracking event', this);
            },
        },
    });

    set({
        user_properties: {
            organization_id: currentUser?.organizationId ?? null,
            organization_name: currentUser?.organizationName ?? null,
            // roles: currentUser?.roles.join(', ') ?? null,
            roles: currentUser?.roles ?? [],
        },
    });
    // inject('$gtag', Vue.config.)
};

export default GTagPlugin;
