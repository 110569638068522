const middleware = {}

middleware['admin'] = require('../src/middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['authenticated'] = require('../src/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['systemAdmin'] = require('../src/middleware/systemAdmin.ts')
middleware['systemAdmin'] = middleware['systemAdmin'].default || middleware['systemAdmin']

export default middleware
