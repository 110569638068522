import { Context } from '@nuxt/types';
import {
    CreateUserParams,
    CreateUserResult,
    CredentialParams,
    PasswordChangeParams,
    PasswordChangeResult,
    UserCurrentOrganizationParams,
} from '@shared/api/UserApiTypes';
import { appModule } from '@util/store-accessor';
import { LoginResult, SignupResult } from '@store/signup';
import {
    CompleteEmailVerificationParams,
    CompleteEmailVerificationResult,
    SendEmailVerificationParams,
    SendEmailVerificationResult,
    ValidateTokenResult,
} from '@shared/api/EmailVerificationTypes';
import { getApiErrorResponse, getErrorResponse } from '@shared/util/ApiResponseUtil';
import { ApiError, isApiError } from '@shared/api/ErrorTypes';
import {
    CreatePasswordResetTokenParams,
    CreatePasswordResetTokenResponse,
    PasswordResetTokenUser,
} from '@shared/api/PasswordResetTokenTypes';
import { SaveUserAssessmentParams, UserAssessmentAggregation } from '@shared/api/UserAssessmentControllerTypes';
import {
    Dashboard,
    DirectoryHeadingTeamMembers,
    IndividualResults,
    SCurveLocation,
    SCurveLocationTeamMembers,
    SearchParams,
    TeamMemberSearchResult,
} from '@shared/api/DashboardTypes';
import {
    CreatePreRegistrationParams,
    DeletePreRegistrationResult,
    PreRegistration,
    UpdatePreRegistrationParams,
} from '@shared/models/PreRegistration';
import { PaginationParams } from '@shared/api/ApiTypes';
import Organization, { DeleteOrganizationUserResult } from '@shared/models/Organization';
import User, { Role } from '@shared/models/User';
import { AllAssessmentsResponse, AssessmentResponse } from '@shared/api/AssessmentControllerTypes';
import { Assessment } from '@shared/models/assessment/Assessment';
import { CreateOrganizationParams, CreateOrganizationResult } from '@shared/api/OrganizationApiTypes';
import { BulkInviteResult, InviteUserResult } from '@api/managers/OrganizationManager.types';

export default class ApiService {
    // eslint-disable-next-line no-use-before-define
    static shared: ApiService;

    static init(context: Context) {
        ApiService.shared = new ApiService(context);
    }

    context: Context;

    constructor(context: Context) {
        this.context = context;
    }

    get $axios() {
        return this.context.$axios;
    }

    get $sentry() {
        return this.context.$sentry;
    }

    get $auth() {
        return this.context.$auth;
    }

    async createUser(params: CreateUserParams): Promise<SignupResult> {
        const { email } = params;
        try {
            const { data: userResult } = await this.$axios.post<CreateUserResult>(`/users`, params);

            const { user } = userResult;
            return {
                user,
                email,
            };
        } catch (error) {
            console.error('unable to create user', error);
            return { user: null, email, error: 'Unable to create user' };
        }
    }

    async submitLogin(params: CredentialParams): Promise<LoginResult> {
        const apiUrl = appModule.apiUrl;
        const { email } = params;
        try {
            const { data } = await this.$axios.post<CreateUserResult>(`${apiUrl}/users/credential`, params);
            return { user: data.user, alert: null, email };
        } catch (error) {
            return {
                user: null,
                email,
                alert: { message: 'Unable to log in' },
            };
        }
    }

    async sendVerificationEmail(params: SendEmailVerificationParams): Promise<SendEmailVerificationResult | ApiError> {
        try {
            return await this.$axios.$post<SendEmailVerificationResult>(`/email-verifications`, params);
        } catch (error) {
            const errorResponse = getErrorResponse<SendEmailVerificationResult>(error);
            if (errorResponse?.data) {
                console.error('failed to send verification email: ', errorResponse.data);
                return errorResponse.data;
            } else {
                console.error('unknown error: Failed to send verification email', error);
                return {
                    sent: false,
                    error: {
                        message: 'An unknown error occurred. Please try again later.',
                    },
                };
            }
        }
    }

    async completeEmailVerification(params: CompleteEmailVerificationParams): Promise<CompleteEmailVerificationResult> {
        try {
            const { token } = params;

            return await this.$axios.$put<CompleteEmailVerificationResult>(`/email-verifications/${token}`, params);
        } catch (error) {
            console.error('failed to complete verification', getErrorResponse(error));
            return { verified: false };
        }
    }

    async validateEmailToken(params: { token: string }): Promise<ValidateTokenResult> {
        const { token } = params;
        try {
            return await this.$axios.$get<ValidateTokenResult>(`/email-verifications/${token}`);
        } catch (error) {
            return { token: null, isValid: false };
        }
    }

    async sendForgotPasswordEmail(
        params: CreatePasswordResetTokenParams
    ): Promise<CreatePasswordResetTokenResponse | ApiError> {
        try {
            return await this.$axios.$post<CreatePasswordResetTokenResponse>('/password-reset-tokens', params);
        } catch (error) {
            const apiError = getErrorResponse<ApiError>(error);
            if (apiError && isApiError(apiError?.data)) {
                return apiError.data;
            }
            return {
                error: { message: 'Unexpected error, please try again later' },
            };
        }
    }

    async getPasswordResetTokenUser(token: string): Promise<PasswordResetTokenUser | null> {
        try {
            return await this.$axios.$get<PasswordResetTokenUser>(`/password-reset-tokens/${token}`);
        } catch (error) {
            console.error('failed to get token', error);
            return null;
        }
    }

    async changePassword(params: { token: string; password: string }): Promise<PasswordChangeResult | ApiError> {
        const payload: PasswordChangeParams = {
            password: params.password,
        };
        try {
            return await this.$axios.$put<PasswordChangeResult>(`/password-reset-tokens/${params.token}`, payload);
        } catch (error) {
            console.error('Failed to update credentials', error);
            const apiError = getApiErrorResponse(error);
            if (apiError) {
                return apiError;
            }
            return { error: { message: 'Unable to reset password' } };
        }
    }

    async getUserAssessmentAggregation(params: {
        userAssessmentId: number;
    }): Promise<UserAssessmentAggregation | null> {
        const { userAssessmentId } = params;
        try {
            const aggregationResult = await this.$axios.$get<UserAssessmentAggregation | ApiError>(
                `/user-assessments/${userAssessmentId}`
            );
            if (isApiError(aggregationResult)) {
                console.error('Failed to get user assessment aggregation', aggregationResult);
                return null;
            }
            return aggregationResult;
        } catch (error) {
            const apiError = getApiErrorResponse(error);
            if (isApiError(apiError)) {
                console.error('Failed to get user assessment aggregation', apiError);
            } else {
                console.error('Unknown error getting user assessment aggregation', error);
            }
            return null;
        }
    }

    async getCurrentUserAssessment(): Promise<UserAssessmentAggregation | null> {
        try {
            return await this.$axios.$get<UserAssessmentAggregation>('/user-assessments/current');
        } catch (error) {
            console.error('Failed to get the current user assessment', error);
            return null;
        }
    }

    async createUserAssessment(): Promise<UserAssessmentAggregation | null> {
        try {
            return await this.$axios.$post<UserAssessmentAggregation>('/user-assessments');
        } catch (error) {
            console.error('Unable to create user assessment', error);
            return null;
        }
    }

    async saveUserAssessmentAnswers(params: SaveUserAssessmentParams): Promise<void> {
        const { userAssessmentId } = params;
        try {
            await this.$axios.$put(`/user-assessments/${userAssessmentId}/answers`, params);
        } catch (error) {
            console.error('failed to save answers', error);
        }
    }

    async fetchDashboard(): Promise<Dashboard | null> {
        try {
            return await this.$axios.$get<Dashboard>('/dashboard');
        } catch (error) {
            console.error('Failed to get dashboard', error);
            return null;
        }
    }

    async fetchIndividualResults(userIds: string[]): Promise<IndividualResults[]> {
        try {
            const resp = await this.$axios.get<IndividualResults[]>('/user-assessments/results', {
                params: { userIds: userIds.join(',') },
            });
            return resp.data;
        } catch (error) {
            console.error('failed to get individual results', error);
        }

        return [];
    }

    async getTeamMembersForSCurveLocation(location: SCurveLocation): Promise<SCurveLocationTeamMembers | null> {
        try {
            const res = await this.$axios.get<SCurveLocationTeamMembers>('/dashboard/s-curve/team-members', {
                params: { ...location },
            });
            return res.data;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error('Error fetching teamMembers for scurve location', e);
            return null;
        }
    }

    async searchTeamMembers(params: SearchParams): Promise<TeamMemberSearchResult | null> {
        try {
            return await this.$axios.$get<TeamMemberSearchResult | null>('/dashboard/team-members', { params });
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error('Error searching for teamMembers', params, e);
            return null;
        }
    }

    async getTeamMembersForDirectoryHeading(params: { heading: string }): Promise<DirectoryHeadingTeamMembers> {
        const { heading } = params;
        try {
            const res = await this.$axios.get<DirectoryHeadingTeamMembers>(`/dashboard/directory/${heading}`);
            return res.data;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error('Error fetching teamMembers for directory heading', e);
            return { heading, teamMembers: [] };
        }
    }

    async postPreRegistration(params: CreatePreRegistrationParams): Promise<InviteUserResult | null> {
        try {
            const res = await this.$axios.post<InviteUserResult>(`/pre-registrations`, params);
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async updatePreRegistration(
        id: number,
        params: UpdatePreRegistrationParams
    ): Promise<{ updated: PreRegistration | null } | null> {
        try {
            const res = await this.$axios.put<{
                updated: PreRegistration | null;
            }>(`/pre-registrations/${id}`, params);
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async removeOrganizationUserRoles({
        userId,
        organizationId,
        roles,
    }: {
        userId: string;
        organizationId: number;
        roles: Role[];
    }): Promise<{ roles: Role[] } | null> {
        try {
            const res = await this.$axios.delete<{
                roles: Role[];
            }>(`/organizations/${organizationId}/users/${userId}/roles`, {
                data: { roles },
            });
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async insertOrganizationUserRoles({
        userId,
        organizationId,
        roles,
    }: {
        userId: string;
        organizationId: number;
        roles: Role[];
    }): Promise<{ roles: Role[] } | null> {
        try {
            const res = await this.$axios.post<{
                roles: Role[];
            }>(`/organizations/${organizationId}/users/${userId}/roles`, {
                roles,
            });
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async setOrganizationUserRoles({
        userId,
        organizationId,
        roles,
    }: {
        userId: string;
        organizationId: number;
        roles: Role[];
    }): Promise<{ roles: Role[] } | null> {
        try {
            const res = await this.$axios.put<{
                roles: Role[];
            }>(`/organizations/${organizationId}/users/${userId}/roles`, {
                roles,
            });
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async getOrganizationUserRoles({
        userId,
        organizationId,
    }: {
        userId: string;
        organizationId: number;
    }): Promise<{ roles: Role[] } | null> {
        try {
            const res = await this.$axios.get<{
                roles: Role[];
            }>(`/organizations/${organizationId}/users/${userId}/roles`);
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async deletePreRegistration(params: { preRegistrationId: number }): Promise<DeletePreRegistrationResult | null> {
        try {
            const res = await this.$axios.delete<DeletePreRegistrationResult>(
                `/pre-registrations/${params.preRegistrationId}`
            );
            return res.data ?? null;
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return null;
        }
    }

    async deleteOrganizationUser(params: {
        userId: string;
        organizationId: number;
    }): Promise<DeleteOrganizationUserResult | null> {
        try {
            const res = await this.$axios.delete<DeleteOrganizationUserResult>(
                `/organizations/${params.organizationId}/users/${params.userId}`
            );
            return res.data ?? null;
        } catch (error) {
            return null;
        }
    }

    async postBulkPreRegistrations(params: CreatePreRegistrationParams[]): Promise<BulkInviteResult | null> {
        try {
            const response = await this.$axios.$post<BulkInviteResult>('/pre-registrations/bulk/', params);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * Fetch the organizations this user belongs to
     * @param {PaginationParams} params
     * @return {Promise<Organization[]>}
     */
    async fetchUserOrganizations(params?: PaginationParams): Promise<Organization[]> {
        try {
            return await this.$axios.$get<Organization[]>('/users/current/organizations', {
                params,
            });
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return [];
        }
    }

    /**
     * Get all organizations. Requires SYSTEM_ADMIN role.
     * @param {PaginationParams} params
     * @return {Promise<Organization[]>}
     */
    async fetchAllOrganizations(params?: PaginationParams): Promise<Organization[]> {
        try {
            return await this.$axios.$get<Organization[]>('/organizations', {
                params,
            });
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return [];
        }
    }

    /**
     * Search orgnizations. Requires SYSTEM_ADMIN role.
     * @param {PaginationParams} params
     * @return {Promise<Organization[]>}
     */
    async searchOrganizations(params?: PaginationParams & { search: string }): Promise<Organization[]> {
        try {
            return await this.$axios.$get<Organization[]>('/organizations', {
                params,
            });
        } catch (error) {
            const e = getApiErrorResponse(error) ?? error;
            console.error(e);
            return [];
        }
    }

    async createOrganization(params: CreateOrganizationParams): Promise<Organization | null> {
        console.log('create org params', params);
        try {
            const { organization } = await this.$axios.$post<CreateOrganizationResult>('/organizations', params);
            return organization ?? null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async setCurrentOrganization(
        params: UserCurrentOrganizationParams
    ): Promise<{ success: boolean; user?: User | null }> {
        try {
            const { user, success } = await this.$axios.$put<{
                user: User;
                success: boolean;
            }>('/users/current-organization', params);
            console.log('Switched user successfully. Updated user:', user);
            console.log('Refreshing auth tokens for new configs');
            await this.$auth.refreshTokens();

            return { user, success };
        } catch (error) {
            console.error('Failed to change the current org');
            console.error(error);
            return { success: false };
        }
    }

    async fetchAllAssessments(params?: PaginationParams): Promise<Assessment[]> {
        try {
            const { assessments } = await this.$axios.$get<AllAssessmentsResponse>('/assessments', {
                params,
            });
            return assessments;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async fetchAssessmentDetails(params: { assessmentId: number }): Promise<AssessmentResponse | null> {
        try {
            return await this.$axios.$get<AssessmentResponse>(`/assessments/${params.assessmentId}`);
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
