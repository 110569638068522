import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=32952806&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=ts&"
export * from "./dashboard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32952806",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBarDrawer: require('/tmp/build_ae374436/src/components/NavBarDrawer.vue').default,NavBar: require('/tmp/build_ae374436/src/components/NavBar.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VContainer,VMain})
