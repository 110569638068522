/**
 * Numeric enum representing the placement, High or Low, within a section
 */
import { isNumber } from '@shared/util/TypeGuards';

export enum SCurvePlacement {
    LOW = 0,
    HIGH = 1,
}

export enum SCurveSection {
    LAUNCH = 0,
    SWEET_SPOT = 1,
    MASTERY = 2,
}

export function isSCurveSection(input: number): input is SCurveSection {
    return Object.keys(SCurveSection).map(Number).filter(isNumber).includes(input);
}

export function isSCurvePlacement(input: number): input is SCurvePlacement {
    return Object.keys(SCurvePlacement).map(Number).filter(isNumber).includes(input);
}

export interface UserAssessment {
    userAssessmentId: number;
    userId: string;
    assessmentId: number;
    startedAt: number | null;
    completedAt: number | null;
    resultSCurvePlacement: SCurvePlacement | null;
    resultSCurveSection: SCurveSection | null;
}

export interface CompletedUserAssessment extends UserAssessment {
    completedAt: number;
    resultSCurvePlacement: SCurvePlacement;
    resultSCurveSection: SCurveSection;
}
