import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
    Dashboard,
    DirectoryHeading,
    DirectoryHeadingTeamMembers,
    IndividualResults,
    SCurveLocation,
    SCurveSectionDistributionIdeals,
    SCurveSectionInfo,
    TeamMember,
    TeamResults,
    TeamScurveSectionSummary,
} from '@shared/api/DashboardTypes';
import ApiService from '@client/sevices/ApiService';
import { isSCurveSection, SCurveSection } from '@shared/models/assessment/UserAssessment';
import copy from '@shared/constants/copy';
import { isDefined, isNumber } from '@shared/util/TypeGuards';
import { SelectedPanel } from '@components/DirectoryTypes';
import AnalyticsService from '@client/sevices/AnalyticsService';
import ReportSection from '@shared/models/ReportSection';

enum LoadingStatus {
    NOT_LOADED = 'NOT_LOADED',
    LOADING = 'LOADING',
    FINISHED = 'FINISHED',
}

type LocationKey = string;

@Module({
    name: 'dashboard',
    stateFactory: true,
    namespaced: true,
})
export default class DashboardModule extends VuexModule {
    loading: boolean = false;
    teamResults: TeamResults | null = null;
    individualResults: IndividualResults | null = null;
    reportSections: ReportSection[] | null = null;

    /**
     * The userIds selected to filter the dashboard
     * @type {string[]}
     */
    selectedUserIds: string[] = [];

    loadingTeamResults: boolean = false;
    teamResultsById: Record<LocationKey, IndividualResults> = {};

    selectedLocation: SCurveLocation | null = null;
    loadingLocationTeamMembers: boolean = false;
    locationTeamMemberStatus: Record<LocationKey, boolean> = {};
    locationTeamMembers: Record<LocationKey, TeamMember[]> = {};

    directoryStatusByHeading: Record<string, LoadingStatus> = {};

    searchLoading: boolean = false;
    searchStatusByQuery: Record<string, LoadingStatus> = {};

    @Mutation
    resetState() {
        this.loading = false;
        this.teamResults = null;
        this.selectedUserIds = [];
        this.loadingTeamResults = false;
        this.teamResultsById = {};
        this.selectedLocation = null;
        this.loadingLocationTeamMembers = false;
        this.locationTeamMemberStatus = {};
        this.locationTeamMembers = {};
        this.directoryStatusByHeading = {};
        this.reportSections = null;
    }

    get directoryHeadings(): DirectoryHeading[] {
        return (this.teamResults?.directoryHeadings ?? []).map((heading) => {
            return {
                ...heading,
                loading: this.directoryStatusByHeading[heading.label] === LoadingStatus.LOADING,
            };
        });
    }

    get selectedTeamMembers(): TeamMember[] {
        return this.teamResults?.teamMembers.filter((m) => m.userId && this.selectedUserIds.includes(m.userId)) ?? [];
    }

    get teamSCurveSectionSummary(): TeamScurveSectionSummary {
        const summary: TeamScurveSectionSummary = {
            [SCurveSection.LAUNCH]: 0,
            [SCurveSection.SWEET_SPOT]: 0,
            [SCurveSection.MASTERY]: 0,
        };

        if (!this.teamResults) {
            return summary;
        }

        this.teamResults?.scurve.reduce((s, result) => {
            s[result.section] += result.count;
            return s;
        }, summary);

        return summary;
    }

    get teamSCurveSectionInfo(): SCurveSectionInfo[] {
        const summary = this.teamSCurveSectionSummary;
        const totalUsers = this.teamResults?.teamCountSummary.userCount ?? 0;
        // const totalUsers = this.totalUserCount;
        if (!this.teamResults) {
            return [];
        }
        return Object.keys(summary)
            .map((n: string) => {
                const section = Number(n) as SCurveSection;
                if (!isNumber(section)) {
                    return null;
                }
                return section;
            })
            .filter(isNumber)
            .filter(isSCurveSection)
            .map((section: SCurveSection) => {
                const sectionIdealCount = Math.ceil(totalUsers * SCurveSectionDistributionIdeals[section]) ?? 1;
                const sectionTotal = summary[section];
                return {
                    title: copy.scurve.section[section].title,
                    peopleDiff: sectionTotal - sectionIdealCount,
                    ideal: SCurveSectionDistributionIdeals[section],
                    currentCount: sectionTotal,
                    idealCount: sectionIdealCount,
                    currentPercent: totalUsers > 0 ? sectionTotal / totalUsers : 0,
                };
            });
    }

    @Mutation
    setSearchLoading(loading: boolean) {
        this.searchLoading = loading;
    }

    @Mutation
    setSearchQueryStatus(params: { query: string; status: LoadingStatus }) {
        const { query, status } = params;
        this.searchStatusByQuery[(query ?? '').toLowerCase().trim()] = status;
    }

    @Mutation
    appendTeamMembers(members: TeamMember[]) {
        const allMembers = [...(this.teamResults?.teamMembers ?? [])];
        members.forEach((member) => {
            const memberIndex = allMembers.findIndex((existing) => {
                return (member.userId && existing.userId === member.userId) || existing.email === member.email;
            });
            if (memberIndex < 0) {
                allMembers.push(member);
            } else {
                allMembers[memberIndex] = member;
            }
        });

        if (this.teamResults) {
            this.teamResults = {
                ...this.teamResults,
                teamMembers: [...allMembers],
            };
        }
    }

    @Mutation
    removeTeamMembers(members: TeamMember[]) {
        const allMembers = [...(this.teamResults?.teamMembers ?? [])];

        const filteredMembers = allMembers.filter((existing) => {
            const shouldRemove = members.some(
                (toRemove) =>
                    toRemove.userId === existing.userId &&
                    toRemove.preRegistrationId === existing.preRegistrationId &&
                    toRemove.email === existing.email
            );
            if (shouldRemove) {
                console.log('[DashboardModule] Filtering out member', existing.email);
            }
            return !shouldRemove;
        });

        if (this.teamResults) {
            this.teamResults = {
                ...this.teamResults,
                teamMembers: [...filteredMembers],
            };
        }
    }

    @Mutation
    increaseHeading(member: TeamMember) {
        const letter = (member.lastName ?? '')[0] ?? '';
        if (!letter) {
            return;
        }

        const results = this.teamResults;
        if (!results) {
            return;
        }
        const headings = results.directoryHeadings;
        let directoryHeading = headings.find((h) => h.label.toUpperCase() === letter.toUpperCase());
        if (directoryHeading) {
            directoryHeading.count += 1;
        } else {
            directoryHeading = { label: letter.toUpperCase(), count: 1 };
            headings.push(directoryHeading);
            this.directoryStatusByHeading[letter.toUpperCase()] = LoadingStatus.NOT_LOADED;
        }

        this.teamResults = {
            ...results,
            directoryHeadings: [...headings],
        };
    }

    @Mutation
    setTeamMembers(members: TeamMember[]) {
        if (this.teamResults) {
            this.teamResults = {
                ...this.teamResults,
                teamMembers: [...members],
            };
        }
    }

    @Mutation
    setDirectoryHeadingStatus(params: { heading: string; status: LoadingStatus }) {
        const { heading, status } = params;
        const copy = { ...this.directoryStatusByHeading };
        copy[heading] = status;
        this.directoryStatusByHeading = copy;
    }

    @Mutation
    setLoadingLocationTeamMembers(loading: boolean) {
        this.loadingLocationTeamMembers = loading;
    }

    static getLocationKey(location: SCurveLocation): LocationKey {
        return `${location.section}_${location.placement}`;
    }

    @Mutation
    setLocationTeamMembers(params: { location: SCurveLocation; status: boolean; teamMembers?: TeamMember[] }) {
        const { location, status, teamMembers } = params;
        const key = DashboardModule.getLocationKey(location);
        this.locationTeamMemberStatus[key] = status;
        this.locationTeamMembers[key] = teamMembers ?? [];
    }

    @Mutation
    setSelectedLocation(location: SCurveLocation | null) {
        this.selectedLocation = location;
    }

    @Mutation
    setLoadingTeamResults(loading: boolean) {
        this.loadingTeamResults = loading;
    }

    @Mutation
    updateTeamResultsById(results: IndividualResults[]) {
        const updated = { ...this.teamResultsById };
        results.forEach((r) => {
            updated[r.userId] = r;
        });
        this.teamResultsById = updated;
    }

    @Mutation
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    @Mutation
    setSelectedUserId(userId: string | null) {
        this.selectedUserIds = userId ? [userId] : [];
    }

    @Mutation
    setSelectedUserIds(userIds: string[] | null) {
        this.selectedUserIds = userIds ?? [];
    }

    @Mutation
    removeSelectedUserId(userId: string | null) {
        this.selectedUserIds = this.selectedUserIds.filter((id) => id !== userId);
    }

    @Mutation
    setDashboardData(dashboard: Dashboard | null) {
        this.loading = false;
        if (!dashboard) {
            this.teamResults = null;
            this.individualResults = null;
            this.reportSections = null;
        } else {
            this.teamResults = dashboard.teamResults;
            this.individualResults = dashboard.individualResults;
            this.reportSections = dashboard.reportSections ?? null;
        }
    }

    @Action
    async loadDashboard(): Promise<Dashboard | null> {
        this.setLoading(true);
        const dashboard = await ApiService.shared.fetchDashboard();
        this.setDashboardData(dashboard);
        return dashboard;
    }

    @Action
    async filterByUserIds(input: string | string[] | null): Promise<IndividualResults[]> {
        let ids: string[] | null = [];
        if (Array.isArray(input)) {
            ids = input;
        } else if (isDefined(input)) {
            ids.push(input);
        } else {
            ids = null;
        }
        this.setSelectedUserIds(ids);
        if (isDefined(ids)) {
            AnalyticsService.shared.teamMemberSelected();
            return this.fetchIndividualResultsForUserIds(ids);
        } else {
            AnalyticsService.shared.teamMemberCleared();
        }
        return [];
    }

    /**
     * Fetch individual results for a given set of userIds
     * @param {string[]} userIds
     * @return {Promise<IndividualResults[]>}
     */
    @Action
    async fetchIndividualResultsForUserIds(userIds: string[]): Promise<IndividualResults[]> {
        const idsToFetch: Set<string> = new Set(
            userIds.filter((id) => {
                return !isDefined(this.teamResultsById[id]);
            })
        );
        // const idsToFetch = new Set(userIds);
        const existingResults: IndividualResults[] = [];
        userIds.forEach((id) => {
            const r = this.teamResultsById[id];
            if (isDefined(r)) {
                existingResults.push(r);
            }
        });

        if (idsToFetch.size === 0) {
            return existingResults;
        }
        this.setLoadingTeamResults(true);
        const results = await ApiService.shared.fetchIndividualResults([...idsToFetch]);
        this.updateTeamResultsById(results);
        this.setLoadingTeamResults(false);
        return [...results, ...existingResults];
    }

    @Action
    async fetchTeamMembersForLocation(location: SCurveLocation): Promise<TeamMember[] | null> {
        const locationKey = DashboardModule.getLocationKey(location);
        const locationStatus = this.locationTeamMemberStatus[locationKey];

        if (locationStatus) {
            return this.locationTeamMembers[locationKey] ?? [];
        }
        this.setLoadingLocationTeamMembers(true);

        const result = await ApiService.shared.getTeamMembersForSCurveLocation(location);

        if (result) {
            this.setLocationTeamMembers({
                location,
                status: true,
                teamMembers: result.teamMembers,
            });
            this.appendTeamMembers(result.teamMembers);
        } else {
            this.setLocationTeamMembers({
                location,
                status: false,
            });
        }

        this.setLoadingLocationTeamMembers(false);
        return null;
    }

    @Action
    async loadDirectoryPanels(panels: SelectedPanel[]): Promise<void> {
        const panelStatus = this.directoryStatusByHeading;
        const panelsToLoad = panels.filter(
            (p) => (panelStatus[p.name] ?? LoadingStatus.NOT_LOADED) === LoadingStatus.NOT_LOADED
        );
        const tasks: Promise<DirectoryHeadingTeamMembers>[] = panelsToLoad.map((heading) => {
            this.setDirectoryHeadingStatus({
                heading: heading.name,
                status: LoadingStatus.LOADING,
            });

            return ApiService.shared.getTeamMembersForDirectoryHeading({
                heading: heading.name,
            });
        });

        const results = await Promise.all(tasks);
        const directoryMembers: TeamMember[] = [];

        results.forEach((r) => {
            directoryMembers.push(...r.teamMembers);

            this.setDirectoryHeadingStatus({
                heading: r.heading,
                status: LoadingStatus.FINISHED,
            });
        });
        this.appendTeamMembers(directoryMembers);
    }

    @Action
    async searchForTeamMembers(_query: string) {
        const q = _query.toLowerCase()?.trim();
        const status = this.searchStatusByQuery[q] ?? LoadingStatus.NOT_LOADED;
        if (status !== LoadingStatus.NOT_LOADED) {
            return;
        }
        this.setSearchLoading(true);
        this.setSearchQueryStatus({ query: q, status: LoadingStatus.LOADING });

        const results = await ApiService.shared.searchTeamMembers({ query: q });
        if ((results?.results ?? []).length > 0) {
            this.appendTeamMembers(results?.results ?? []);
        }

        this.setSearchLoading(false);
        this.setSearchQueryStatus({ query: q, status: LoadingStatus.FINISHED });
    }
}
