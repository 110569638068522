import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Organization, { SimpleOrganization } from '@shared/models/Organization';
import { PaginationParams } from '@shared/api/ApiTypes';
import ApiService from '@client/sevices/ApiService';
import User from '@shared/models/User';

const ORGANIZATION_PAGE_SIZE = 500;

@Module({
    name: 'systemAdmin',
    stateFactory: true,
    namespaced: true,
})
export default class SystemAdminModule extends VuexModule {
    organizationsLoading: boolean = false;
    organizationsMightHaveMore: boolean = true;
    organizations: Organization[] = [];
    organizationsById: Record<number, Organization> = {};

    savingCurrentOrganization: boolean = false;
    selectedOrganization: SimpleOrganization | null = null;

    @Mutation
    setOrganizations(params: { organizations: Organization[]; mightHaveMore: boolean }) {
        this.organizations = params.organizations;
        this.organizationsMightHaveMore = params.mightHaveMore;

        const orgMap: Record<number, Organization> = {};
        this.organizationsById = params.organizations.reduce((map, org) => {
            map[org.organizationId] = org;
            return map;
        }, orgMap);
    }

    @Mutation
    setOrganization(organization: Organization) {
        const updatedOrgs = this.organizations.filter((org) => org.organizationId !== organization.organizationId);
        updatedOrgs.push(organization);
        this.organizations = updatedOrgs;
        this.organizationsById[organization.organizationId] = organization;
    }

    @Mutation
    setSavingCurrentOrganization(params: { saving: boolean }) {
        this.savingCurrentOrganization = params.saving;
    }

    @Mutation
    setOrganizationsLoading(params: { loading: boolean }) {
        this.organizationsLoading = params.loading;
    }

    @Mutation
    setSelectedOrganization(params: { organization: SimpleOrganization | null }) {
        this.selectedOrganization = params.organization;
    }

    @Action
    async saveCurrentOrganization(): Promise<{
        success: boolean;
        user?: User | null;
    }> {
        const organizationId = this.selectedOrganization?.organizationId;
        if (!organizationId) {
            console.info('no organization selected');
            return { success: false };
        }
        this.setSavingCurrentOrganization({ saving: true });
        const saveResult = await ApiService.shared.setCurrentOrganization({
            organizationId,
        });

        if (!saveResult || !saveResult.success) {
            console.log('Unable to update the current organization');
            return saveResult;
        }
        const user = saveResult.user;
        console.log('Successfully updated the current organization for the user');
        this.setSavingCurrentOrganization({ saving: false });
        return { success: true, user };
    }

    @Action
    async fetchOrganizations(params?: PaginationParams): Promise<Organization[]> {
        const { offset = 0, limit = ORGANIZATION_PAGE_SIZE } = params ?? {};
        if (this.organizationsLoading || !this.organizationsMightHaveMore) {
            console.log('already loading organizations, not loading any more');
            return this.organizations;
        }

        this.setOrganizationsLoading({ loading: true });

        const fetchedOrgs = await ApiService.shared.fetchAllOrganizations({
            offset,
            limit,
        });

        const mightHaveMore = fetchedOrgs.length < limit;

        this.setOrganizations({ organizations: fetchedOrgs, mightHaveMore });
        this.setOrganizationsLoading({ loading: false });
        return this.organizations;
    }

    @Action
    async searchOrganizations(params?: PaginationParams & { search?: string }): Promise<Organization[]> {
        const { offset = 0, limit = ORGANIZATION_PAGE_SIZE, search = '' } = params ?? {};
        // if (this.organizationsLoading || !this.organizationsMightHaveMore) {
        //     console.log('already loading organizations, not loading any more');
        //     return this.organizations;
        // }

        // this.setOrganizationsLoading({ loading: true });

        const fetchedOrgs = await ApiService.shared.searchOrganizations({
            offset,
            limit,
            search,
        });
        // const mightHaveMore = fetchedOrgs.length < limit;
        // this.setOrganizations({ organizations: fetchedOrgs, mightHaveMore });
        // this.setOrganizationsLoading({ loading: false });

        return fetchedOrgs;
    }
}
