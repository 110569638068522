export const light = {
    primary: '#DA2128',
    secondary: '#017381',
    accent: '#052049',
    error: '#af191d',
    warning: '#F2994A',
    info: '#017381',
    success: '#089982',
    anchor: '#017381',
};
export default {
    themes: {
        light,
    },
    options: {
        customProperties: true,
    },
};
