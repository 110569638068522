export enum RouteName {
    assessment = 'assessment',
    dashboard = 'dashboard-index',
    dashboardYourResults = 'dashboard-index-your-results',
    dashboardManageTeam = 'dashboard-index-manage-team',
    dashboardSystemAdmin = 'dashboard-index-system-admin',
    login = 'login',
    signup = 'signup',
    userAssessment = 'assessment-userAssessmentId',
    verifyEmailCompleted = 'verify-email-completed',
    verifyEmailSent = 'verify-email-sent',
}

export enum RoutePath {
    verifyEmailCompleted = '/verify-email/completed',
    verifyEmailSent = '/verify-email/sent',
}
