import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import User from '@shared/models/User';
import { isValidEmail } from '@shared/util/StringUtil';
import { CreateUserParams, CredentialParams } from '@shared/api/UserApiTypes';
import { Alert } from '@shared/errors/Alert';
import {
    CompleteEmailVerificationParams,
    CompleteEmailVerificationResult,
    SendEmailVerificationParams,
    SendEmailVerificationResult,
    ValidateTokenResult,
} from '@shared/api/EmailVerificationTypes';
import ApiService from '@client/sevices/ApiService';
import { ApiError, ErrorCode, isApiError } from '@shared/api/ErrorTypes';
import AnalyticsService, { AnalyticsEvent } from '@client/sevices/AnalyticsService';

export interface LoginResult {
    email: string;
    alert?: Alert | null;
    user?: User | null;
}

export interface SignupResult {
    email: string;
    error?: string;
    user?: User | null;
}

@Module({
    name: 'signup',
    stateFactory: true,
    namespaced: true,
})
export default class SignupModule extends VuexModule {
    currentUser: User | null = null;
    signupError: string | null = null;
    email: string | null = null;
    sendingVerificationEmail: boolean = false;
    verificationSent: boolean = false;
    alert: Alert | null = null;
    verifiedEmailSuccess: boolean = false;
    verifiedTokenEmail: string | null = null;
    sendVerificationResult: SendEmailVerificationResult | ApiError | null = null;

    showLogin: boolean = false;
    login: {
        email: string | null;
        alert: Alert | null;
    } = { email: null, alert: null };

    creatingUser: boolean = false;
    emailTokenValidation: ValidateTokenResult | null = null;

    @Mutation
    resetState() {
        this.currentUser = null;
        this.signupError = null;
        this.email = null;
        this.verificationSent = false;
        this.alert = null;
        this.verifiedEmailSuccess = false;
        this.verifiedTokenEmail = null;
        this.sendVerificationResult = null;
        this.showLogin = false;
        this.login = { email: null, alert: null };
        this.emailTokenValidation = null;
        this.creatingUser = false;
    }

    @Mutation
    setCreatingUser(creating: boolean) {
        this.creatingUser = creating;
    }

    @Mutation
    setEmail(email: string | null) {
        this.email = email;
    }

    @Mutation
    setSendingVerificationEmail(sending: boolean) {
        this.sendingVerificationEmail = sending;
    }

    get userId(): string | null {
        return this.currentUser?.id ?? null;
    }

    get isLoggedIn(): boolean {
        return !!this.currentUser;
    }

    get tokenEmail(): string | null {
        return this.emailTokenValidation?.token?.email ?? null;
    }

    @Mutation
    setCurrentUser(user: User | null) {
        this.currentUser = user;
    }

    @Mutation
    setShowLoginModal(show: boolean) {
        this.showLogin = show;
    }

    @Mutation
    clearSignupError() {
        this.signupError = null;
    }

    @Mutation
    setSignupResult(params: SignupResult) {
        this.signupError = params.error ?? null;
        this.email = params.email ?? null;
        this.currentUser = params.user ?? null;
    }

    @Mutation
    setLoginResult(params: LoginResult) {
        this.login.alert = params.alert ?? null;
        this.login.email = !params.user ? params.email : null;
        this.currentUser = params.user ?? null;
    }

    @Mutation
    setSendVerificationResult(params: SendEmailVerificationResult | ApiError) {
        if (isApiError(params)) {
            this.alert = {
                message: 'Unable to send verification email',
            };
        } else {
            this.verificationSent = params.sent;
        }
    }

    @Action({ commit: 'setLoginResult' })
    async submitLogin(params: CredentialParams): Promise<LoginResult> {
        return await ApiService.shared.submitLogin(params);
    }

    @Action
    async createUser(params: CreateUserParams): Promise<SignupResult> {
        this.setCreatingUser(true);
        const result = await ApiService.shared.createUser(params);
        this.setSignupResult(result);
        this.setCreatingUser(false);
        return result;
    }

    @Mutation
    setSendEmailVerificationResult(params: SendEmailVerificationResult | ApiError | null) {
        if (isApiError(params)) {
            switch (params.error.code) {
                case ErrorCode.USER_ALREADY_EXISTS:
                    this.sendVerificationResult = null;
                    this.showLogin = true;
                    break;
                default:
                    this.showLogin = false;
                    this.sendVerificationResult = params;
            }
        } else {
            this.showLogin = false;
            this.sendVerificationResult = params;
        }
    }

    @Action
    async submitPreRegistration(email: string): Promise<SendEmailVerificationResult | ApiError> {
        if (!isValidEmail(email)) {
            console.error('Not a valid email - can not submit');
            return {
                sent: false,
                error: { message: 'Please enter a valid email address.' },
            };
        }
        this.setEmail(email);
        this.setSendingVerificationEmail(true);
        AnalyticsService.shared.sendEvent(AnalyticsEvent.emailVerificationRequest);
        const [result] = await Promise.all([
            ApiService.shared.sendVerificationEmail({
                email,
            }),
            new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1000);
            }),
        ]);

        if (isApiError(result) && result.error?.code === ErrorCode.EMAIL_NOT_FOUND) {
            AnalyticsService.shared.sendEvent(AnalyticsEvent.emailNotPreRegistered, {
                email,
            });
        } else if (!isApiError(result) && result.sent) {
            AnalyticsService.shared.sendEvent(AnalyticsEvent.emailVerificationSent);
        }

        this.setSendEmailVerificationResult(result);
        this.setSendingVerificationEmail(false);

        return result;
    }

    @Action
    async resendEmailVerification(params: SendEmailVerificationParams) {
        this.setSendingVerificationEmail(true);
        AnalyticsService.shared.sendEvent(AnalyticsEvent.resendEmailVerificationRequest);
        const [result] = await Promise.all([
            ApiService.shared.sendVerificationEmail(params),
            new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1000);
            }),
        ]);
        this.setSendVerificationResult(result);
        this.setSendingVerificationEmail(false);

        if (isApiError(result) && result.error?.code === ErrorCode.EMAIL_NOT_FOUND) {
            AnalyticsService.shared.sendEvent(AnalyticsEvent.emailNotPreRegistered, {
                email: params.email,
            });
        } else if (!isApiError(result) && result.sent) {
            AnalyticsService.shared.sendEvent(AnalyticsEvent.emailVerificationSent);
        }
    }

    @Mutation
    setEmailVerified(params: CompleteEmailVerificationResult) {
        this.verifiedEmailSuccess = params.verified;
        this.verifiedTokenEmail = params.email ?? null;
    }

    @Action({ commit: 'setEmailVerified' })
    async completeEmailVerification(params: CompleteEmailVerificationParams): Promise<CompleteEmailVerificationResult> {
        return await ApiService.shared.completeEmailVerification(params);
    }

    @Mutation
    setEmailTokenValidation(result: ValidateTokenResult | null) {
        this.emailTokenValidation = result;
    }

    @Action({ commit: 'setEmailTokenValidation' })
    async getTokenValidation(params: { token: string }) {
        return await ApiService.shared.validateEmailToken(params);
    }
}
