import { SCurvePlacement, SCurveSection } from '@shared/models/assessment/UserAssessment';
import { AccelerantCategory, EcosystemCategory, QuestionCategory } from '@shared/models/assessment/QuestionCategory';

interface PageCopy {
    title: string;
}

export interface SignupCopy {
    pleaseVerifyYourAccount: string;
    sendingEmail: string;
    pleaseEnterYourEmail: string;
    pleaseEnterYourPassword: string;
    sendResetLink: string;
    welcomeBackExclamation: string;
    tokenUsedOrInvalid: string;
}

type CopyPageName = 'privacy_policy' | 'terms_and_conditions';
export type PageMap = {
    [name in CopyPageName]: PageCopy;
};
type SCurvePlacementCopy = {
    [placement in SCurvePlacement]: {
        title: string;
    };
};
type SCurveSectionCopy = {
    [section in SCurveSection]: {
        title: string;
        descriptionLong: string;
        tooltip: string;
    };
};

export interface SCurveCopy {
    placement: SCurvePlacementCopy;
    section: SCurveSectionCopy;
}

type QuestionCategoryItemCopy = {
    title: string;
    tooltip: string;
};
type QuestionCategoryCopyMap<T extends QuestionCategory> = {
    [item in T]: QuestionCategoryItemCopy;
};
export type AccelerantCategoryCopyMap = QuestionCategoryCopyMap<AccelerantCategory>;
export type EcosystemCategoryCopyMap = QuestionCategoryCopyMap<EcosystemCategory>;
export type DashboardSectionCopy = { sectionTitle: string };
export type AccelerantInsightsSectionCopy = {
    insightsTitle: string;
};

export interface AssessmentCopy {
    processingResults: string;
}

export const DashboardCopy = {
    tabs: {
        manageTeam: 'Manage Team',
        systemAdmin: 'System Admin',
        teamResults: 'Team Results',
        yourResults: 'Your Results',
    },
    yourTeam: 'Your Team',
};

export type DashboardCopyType = typeof DashboardCopy;
