import { Context, Plugin } from '@nuxt/types';

const CypressPlugin: Plugin = (context: Context) => {
    // @ts-ignore
    if (window && (window.Cypress || window.cy)) {
        console.log('[plugin/cypress] adding nuxt app to window');
        // @ts-ignore
        window.app = context.app;

        // @ts-ignore
        window.nuxtContext = context;
    }
};

export default CypressPlugin;
