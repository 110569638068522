import theme from '@vuetify/theme';

export default () => {
    return {
        theme,
        icons: {
            iconfont: 'mdiSvg',
        },
    };
};
