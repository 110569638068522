import {
    AccelerantCategory,
    getSCurveSectionCategory,
    isAccelerantCategory,
    QuestionCategory,
} from '@shared/models/assessment/QuestionCategory';
import { Question } from '@shared/models/assessment/Question';
import { AnswerMap } from '@shared/models/assessment/UserAssessmentResponse';
import AssessmentConfig from '@shared/models/assessment/AssessmentConfig';
import SCurveResult from '@shared/models/assessment/SCurveResult';
import { AccelerantItem, CategoryScore } from '@shared/api/DashboardTypes';
import { SCurvePlacement, SCurveSection } from '@shared/models/assessment/UserAssessment';

/**
 * Determine the placement within the section
 * @param {Question[]} questions
 * @param {AnswerMap} answers
 * @param {AssessmentConfig} config
 * @return {SCurvePlacement}
 */
export function getSCurveResults(questions: Question[], answers: AnswerMap, config: AssessmentConfig): SCurveResult {
    const section = getScurveSection(questions, answers, config);
    const sectionCategory = getSCurveSectionCategory(section);
    const sectionScore = getCategoryScore(questions, answers, sectionCategory);
    const placement = getScurveSectionPlacementFromScore(sectionScore, config);
    return { section, placement };
}

/**
 * Determine the section of the SCurve the results of the assessment fall into
 * @param {Question[]} questions
 * @param {AnswerMap} answers
 * @param {AssessmentConfig} config
 * @return {SCurveSectionCategory}
 */
export function getScurveSection(questions: Question[], answers: AnswerMap, config: AssessmentConfig): SCurveSection {
    const score = getScurveLocatorScore(questions, answers);
    return getScurveSectionFromScore(score, config);
}

/**
 * Determine the score by summing up answers to
 * questions with the S_CURVE_LOCATOR category
 * @param {Question[]} questions
 * @param {AnswerMap} answers
 * @return {number} - the total score
 */
export function getScurveLocatorScore(questions: Question[], answers: AnswerMap): number {
    return getCategoryScore(questions, answers, QuestionCategory.S_CURVE_LOCATOR);
}

export function getScurveSectionPlacementFromScore(sectionScore: number, config: AssessmentConfig): SCurvePlacement {
    if (sectionScore <= config.scurvePlacementLowMaxScore) {
        return SCurvePlacement.LOW;
    }
    return SCurvePlacement.HIGH;
}

export function getCategoryScore(questions: Question[], answers: AnswerMap, category: QuestionCategory): number {
    const locatorQuestions = questions.filter((q) => q.categories?.includes(category));
    return locatorQuestions.reduce((total, question) => {
        return total + (answers[question.questionId]?.numberValue ?? 0);
    }, 0);
}

export function getScurveSectionFromScore(locatorScore: number, config: AssessmentConfig): SCurveSection {
    if (locatorScore <= config.scurveLocatorLaunchMaxScore) {
        return SCurveSection.LAUNCH;
    }
    if (locatorScore <= config.scurveLocatorSweetSpotMaxScore) {
        return SCurveSection.SWEET_SPOT;
    }
    return SCurveSection.MASTERY;
}

export function getAccelerantItemsFromScores(categoryScores: CategoryScore[]): AccelerantItem[] {
    return categoryScores
        .filter((c) => isAccelerantCategory(c.category))
        .sort((a, b) => b.score - a.score)
        .map((c) => {
            return {
                accelerant: c.category as AccelerantCategory,
                teamScore: c.score,
                tooltip: c.description ?? 'test tooltip',
            };
        });
}

export function getEcosystemResultsFromScores(categoryScores: CategoryScore[]): CategoryScore[] {
    return categoryScores
        .filter((s) => s.category.startsWith('ECOSYSTEM_'))
        .sort((a, b) => (a.name?.toLowerCase() ?? a.category).localeCompare(b.name?.toLowerCase() ?? b.category));
}

export const CUT_SCORES = {
    lowMax: 50,
    mediumMax: 80,
    highMax: 100,
};
