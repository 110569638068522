import type { Context, Middleware } from '@nuxt/types';
import { RouteName } from '@shared/routing/PageRoutes';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authMiddleware: Middleware = (context: Context) => {
    const { $auth, redirect } = context;
    if (!$auth.loggedIn) {
        redirect({ name: RouteName.login });
    }
};

export default authMiddleware;
