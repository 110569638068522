export default interface AssessmentConfig {
    assessmentConfigId: number;
    description: string;
    createdBy: string;
    updatedBy: string;
    createdAt: number;
    updatedAt: number;
    scurvePlacementLowMaxScore: number;
    scurveLocatorLaunchMaxScore: number;
    scurveLocatorSweetSpotMaxScore: number;
}

export const DEFAULT_ASSESSMENT_CONFIG = (): AssessmentConfig => ({
    assessmentConfigId: -1,
    description: 'Default Configuration',
    createdBy: 'SYSTEM_ADMIN',
    updatedBy: 'SYSTEM_ADMIN',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    scurvePlacementLowMaxScore: 9,
    scurveLocatorLaunchMaxScore: 9,
    scurveLocatorSweetSpotMaxScore: 15,
});
