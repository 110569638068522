import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _97ecfc9c = () => interopDefault(import('../src/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _58c46a55 = () => interopDefault(import('../src/pages/assessment/index.vue' /* webpackChunkName: "pages/assessment/index" */))
const _b9b0da92 = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _c7da180c = () => interopDefault(import('../src/pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _54578c30 = () => interopDefault(import('../src/pages/dashboard/index/manage-team/index.vue' /* webpackChunkName: "pages/dashboard/index/manage-team/index" */))
const _10047f08 = () => interopDefault(import('../src/pages/dashboard/index/system-admin/index.vue' /* webpackChunkName: "pages/dashboard/index/system-admin/index" */))
const _50625d64 = () => interopDefault(import('../src/pages/dashboard/index/your-results.vue' /* webpackChunkName: "pages/dashboard/index/your-results" */))
const _35184425 = () => interopDefault(import('../src/pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _083dccee = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2db66727 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4458872e = () => interopDefault(import('../src/pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _66c7f6b0 = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _70e8b5f8 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _ebc45058 = () => interopDefault(import('../src/pages/verify-email/completed.vue' /* webpackChunkName: "pages/verify-email/completed" */))
const _7ff231c2 = () => interopDefault(import('../src/pages/verify-email/sent.vue' /* webpackChunkName: "pages/verify-email/sent" */))
const _0b965ecd = () => interopDefault(import('../src/pages/assessment/_userAssessmentId/index.vue' /* webpackChunkName: "pages/assessment/_userAssessmentId/index" */))
const _32397672 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _97ecfc9c,
    name: "admin"
  }, {
    path: "/assessment",
    component: _58c46a55,
    name: "assessment"
  }, {
    path: "/dashboard",
    component: _b9b0da92,
    children: [{
      path: "",
      component: _c7da180c,
      name: "dashboard-index"
    }, {
      path: "manage-team",
      component: _54578c30,
      name: "dashboard-index-manage-team"
    }, {
      path: "system-admin",
      component: _10047f08,
      name: "dashboard-index-system-admin"
    }, {
      path: "your-results",
      component: _50625d64,
      name: "dashboard-index-your-results"
    }]
  }, {
    path: "/forgot-password",
    component: _35184425,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _083dccee,
    name: "login"
  }, {
    path: "/privacy-policy",
    component: _2db66727,
    name: "privacy-policy"
  }, {
    path: "/reset-password",
    component: _4458872e,
    name: "reset-password"
  }, {
    path: "/signup",
    component: _66c7f6b0,
    name: "signup"
  }, {
    path: "/terms-and-conditions",
    component: _70e8b5f8,
    name: "terms-and-conditions"
  }, {
    path: "/verify-email/completed",
    component: _ebc45058,
    name: "verify-email-completed"
  }, {
    path: "/verify-email/sent",
    component: _7ff231c2,
    name: "verify-email-sent"
  }, {
    path: "/assessment/:userAssessmentId",
    component: _0b965ecd,
    name: "assessment-userAssessmentId"
  }, {
    path: "/",
    component: _32397672,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
