import { AxiosError } from 'axios';
import { ApiError, ApiErrorResponse } from '@shared/api/ErrorTypes';

export function isAxiosError<T>(error: any): error is AxiosError<T> {
    return error.isAxiosError;
}

export function getErrorResponse<T>(error: any): { data: T | null; status: number } | null {
    if (!isAxiosError<T>(error) || !error.response) {
        return null;
    }

    const data = error.response.data ?? null;
    return { data, status: error.response.status };
}

export function getApiErrorResponse(error: any): ApiErrorResponse | null {
    const d = getErrorResponse<ApiError>(error);
    if (!d) {
        return null;
    }
    const { data, status } = d;
    if (!data) {
        return null;
    }
    return { ...data, status };
}

export function getErrorStatus(error: any): number | null {
    if (!isAxiosError(error)) {
        return null;
    }
    return error.response?.status ?? null;
}
