import { AccelerantCategory, QuestionCategory } from '@shared/models/assessment/QuestionCategory';
import SCurveResult from '@shared/models/assessment/SCurveResult';
import { TeamCountSummary } from '@shared/models/Organization';
import { SCurvePlacement, SCurveSection } from '@shared/models/assessment/UserAssessment';
import { Role } from '@shared/models/User';
import ReportSection from '@shared/models/ReportSection';

export interface DirectoryHeading {
    label: string;
    count: number;
    loading?: boolean;
}

export interface AccelerantItem {
    accelerant: AccelerantCategory;
    teamScore: number | null;
}

export interface TeamSCurveResult extends SCurveResult {
    count: number;
}

export type TeamScurveSectionSummary = {
    [section in SCurveSection]: number;
};

export interface CategoryScore<T = QuestionCategory> {
    /**
     * The Question Category.
     */
    category: T;

    /**
     * The score, as a percentage.
     */
    score: number;

    /**
     * User-friendly display name for this category
     */
    name: string | null;

    /**
     * User-friendly description of this category. Will be used in tooltips.
     */
    description: string | null;
}

export interface TeamMember {
    firstName: string | null;
    lastName: string | null;
    userId: string | null;
    email: string;
    assessmentCompletedAt: number | null;
    preRegistrationCreatedAt?: number | null;
    preRegistrationId?: number | null;
    isPreRegistrationAdmin?: boolean;
    userCreatedAt?: number | null;
    roles?: Role[];
}

export interface TeamResults {
    scurve: TeamSCurveResult[];
    categoryScores: CategoryScore[];
    accelerants: AccelerantItem[];
    ecosystem: CategoryScore[];
    teamMembers: TeamMember[];
    teamCountSummary: TeamCountSummary;
    directoryHeadings: DirectoryHeading[];
}

export interface IndividualResults {
    userId: string;
    completedAt: number;
    scurveResults: SCurveResult;
    accelerants: AccelerantItem[];
    categoryScores: CategoryScore[];
}

export interface SCurveLocation {
    section: SCurveSection;
    placement: SCurvePlacement | null;
}

export interface SelectedIndividual extends SCurveLocation {
    label: string;
}

export interface SCurveLocationTeamMembers {
    teamMembers: TeamMember[];
    section: SCurveSection;
    placement: SCurvePlacement | null;
}

export interface SearchParams {
    query: string;
    limit?: number;
    offset?: number;
}

export interface SearchResults<T> extends SearchParams {
    results: T[];
}

export type TeamMemberSearchResult = SearchResults<TeamMember>;

export interface DirectoryHeadingTeamMembers {
    heading: string;
    teamMembers: TeamMember[];
}

export interface Dashboard {
    organizationId: number;
    teamResults: TeamResults | null;
    individualResults: IndividualResults | null;
    reportSections?: ReportSection[] | null;
}

export interface SCurveSectionInfo {
    ideal: number;
    peopleDiff: number;
    title: string;
    currentCount: number;
    currentPercent: number;
    idealCount: number;
}

/**
 * The idea proportion in each Section of the scurve
 * @type {{"[SCurveSection.LAUNCH]": number, "[SCurveSection.SWEET_SPOT]": number, "[SCurveSection.MASTERY]": number}}
 */
export const SCurveSectionDistributionIdeals = {
    [SCurveSection.LAUNCH]: 0.15,
    [SCurveSection.SWEET_SPOT]: 0.7,
    [SCurveSection.MASTERY]: 0.15,
};
