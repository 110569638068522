import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Organization from '@shared/models/Organization';
import ApiService from '@client/sevices/ApiService';
import User from '@shared/models/User';

@Module({
    name: 'account',
    stateFactory: true,
    namespaced: true,
})
export default class AccountModule extends VuexModule {
    showAccountSwitcher: boolean = false;
    fetchingOrganizations: boolean = false;
    organizationsById: Record<number, Organization> = {};
    switchOrganizationLoading = false;

    get organizations(): Organization[] {
        return Object.values(this.organizationsById).sort(
            (o1, o2) => o1.name?.toLowerCase().localeCompare((o2.name ?? '').toLowerCase()) ?? 0
        );
    }

    @Mutation
    setFetchingOrganizations({ fetching }: { fetching: boolean }) {
        this.fetchingOrganizations = fetching;
    }

    @Mutation
    setOrganization({ organization }: { organization: Organization }) {
        this.organizationsById = {
            ...this.organizationsById,
            [organization.organizationId]: organization,
        };
    }

    @Mutation
    setAllOrganization({ organizations, reset }: { organizations: Organization[]; reset: boolean }) {
        const tmp = reset
            ? {}
            : {
                  ...this.organizationsById,
              };

        organizations.forEach((org) => {
            tmp[org.organizationId] = org;
        });

        this.organizationsById = tmp;
    }

    @Mutation
    setShowAccountSwitcher(open: boolean) {
        this.showAccountSwitcher = open;
    }

    @Mutation
    setSwitchOrganizationLoading(loading: boolean) {
        this.switchOrganizationLoading = loading;
    }

    @Mutation
    toggleShowAccountSwitcher() {
        this.showAccountSwitcher = !this.showAccountSwitcher;
    }

    @Action
    async fetchOrganizations(): Promise<void> {
        try {
            this.setFetchingOrganizations({ fetching: true });
            const orgs = await ApiService.shared.fetchUserOrganizations();
            this.setAllOrganization({ organizations: orgs, reset: true });
        } catch (error) {
            console.error('failed to fetch organizations');
            console.error(error);
        } finally {
            this.setFetchingOrganizations({ fetching: false });
        }
    }

    @Action
    async switchToOrganization(params: { organizationId: number }): Promise<{ success: boolean; user?: User | null }> {
        const { organizationId } = params;
        if (!organizationId) {
            return { success: false };
        }
        this.setSwitchOrganizationLoading(true);
        const saveResult = await ApiService.shared.setCurrentOrganization({
            organizationId,
        });

        if (!saveResult || !saveResult.success) {
            console.log('Unable to update the current organization');
            return saveResult;
        }
        const user = saveResult.user;
        console.log('Successfully updated the current organization for the user');
        this.setSwitchOrganizationLoading(false);
        return { success: true, user };
    }
}
