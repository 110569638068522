import { QuestionCategory } from '@shared/models/assessment/QuestionCategory';

export type AnswerValue = number | null;

export enum QuestionChoiceType {
    RANGE_SLIDER = 'RANGE_SLIDER',
    RADIO = 'RADIO',
    LIKERT = 'LIKERT',
    NONE = 'NONE',
    LONG_TEXT = 'LONG_TEXT',
}

export enum QuestionChoiceGroup {
    LIKERT_7_POINT = 'LIKERT_7_POINT',
    LIKERT_7_POINT_REVERSE = 'LIKERT_7_POINT_REVERSE',
}

export interface QuestionChoice {
    questionChoiceId?: number;
    label: string;
    value: number;
    displayOrder?: number;
    questionId?: number;
}

export interface Question {
    questionId: number;
    text: string;
    categories?: QuestionCategory[] | null;
    choiceType: QuestionChoiceType;
    choices: QuestionChoice[];
}
