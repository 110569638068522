import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'app',
    stateFactory: true,
    namespaced: true,
})
export default class AppModule extends VuexModule {
    api!: { domain: string; rootPath: string };
    navigationDrawerOpen: boolean = false;

    @Mutation
    setNavigationDrawerOpen(open: boolean) {
        this.navigationDrawerOpen = open;
    }

    @Mutation
    toggleNavigationDrawer() {
        this.navigationDrawerOpen = !this.navigationDrawerOpen;
    }

    @Mutation
    setConfig(params: { apiDomain: string; apiRootPath: string }) {
        this.api = { domain: params.apiDomain, rootPath: params.apiRootPath };
    }

    get apiUrl(): string {
        return `${this.api.domain}${this.api.rootPath}`;
    }
}
