import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { TeamMember } from '@shared/api/DashboardTypes';
import ApiService from '@client/sevices/ApiService';
import {
    CreatePreRegistrationParams,
    PreRegistration,
    UpdatePreRegistrationParams,
} from '@shared/models/PreRegistration';
import { dashboardModule } from '@util/store-accessor';
import { InviteUserStatus } from '@api/managers/OrganizationManager.types';
import { Role } from '@shared/models/User';
import { OrgUserItem } from '@dao/OrganizationUserDAO';

export type RemoveTeamMemberResult = {
    preRegistration?: PreRegistration | null;
    organizationUser?: OrgUserItem | null;
};
export type UpdateMemberParams = {
    member: TeamMember;
    preRegistrationChanges?: UpdatePreRegistrationParams;
    addRoles?: Role[];
    removeRoles?: Role[];
    organizationId: number;
};

@Module({
    name: 'teamAdmin',
    stateFactory: true,
    namespaced: true,
})
export default class TeamAdminModule extends VuexModule {
    selectedTeamMember: TeamMember | null = null;
    addTeamMemberOpen: boolean = false;
    addTeamMemberError: string | null = null;

    @Mutation
    resetState() {
        this.selectedTeamMember = null;
        this.addTeamMemberOpen = false;
    }

    @Mutation
    setAddTeamMemberOpen(open: boolean) {
        this.addTeamMemberOpen = open;
        if (!open) {
            this.addTeamMemberError = null;
        }
    }

    @Mutation
    setAddTeamMemberError(error: string | null) {
        this.addTeamMemberError = error;
    }

    @Mutation
    setSelectedTeamMember(teamMember: TeamMember | null) {
        this.selectedTeamMember = teamMember;
    }

    @Action
    async removeTeamMember({
        member,
        organizationId,
    }: {
        member: TeamMember;
        organizationId: number;
    }): Promise<RemoveTeamMemberResult> {
        const result: RemoveTeamMemberResult = {};
        let hideTeamMember = false;

        const preRegistrationId = member.preRegistrationId;
        if (preRegistrationId) {
            hideTeamMember = true;
            const preRegistrationResult = await ApiService.shared.deletePreRegistration({
                preRegistrationId,
            });
            result.preRegistration = preRegistrationResult?.removed;
        }

        if (member.userId) {
            const orgMemberResult = await ApiService.shared.deleteOrganizationUser({
                organizationId,
                userId: member.userId,
            });
            if (orgMemberResult?.removed) {
                hideTeamMember = true;
                result.organizationUser = orgMemberResult.removed;
            } else {
                hideTeamMember = false;
                console.error(new Error('Failed to remove organization user'));
            }
        }

        if (hideTeamMember) {
            this.setSelectedTeamMember(null);
            dashboardModule.removeTeamMembers([member]);
        }

        return result;
    }

    @Action
    async updateTeamMember({
        member,
        preRegistrationChanges,
        addRoles,
        removeRoles,
        organizationId,
    }: UpdateMemberParams) {
        const preRegistrationId = member.preRegistrationId;
        const updatedMember: TeamMember = {
            ...member,
        };
        if (preRegistrationId && preRegistrationChanges) {
            const result = await ApiService.shared.updatePreRegistration(preRegistrationId, preRegistrationChanges);

            if (result?.updated) {
                updatedMember.isPreRegistrationAdmin = result.updated.isAdmin ?? false;
            }
        }

        const userId = member.userId;
        if (userId && addRoles) {
            const addedRoles = await ApiService.shared.insertOrganizationUserRoles({
                userId,
                organizationId,
                roles: addRoles,
            });
            updatedMember.roles = [...(updatedMember.roles ?? []), ...(addedRoles?.roles ?? [])];
        }

        if (userId && removeRoles) {
            const addedRoles = await ApiService.shared.removeOrganizationUserRoles({
                userId,
                organizationId,
                roles: removeRoles,
            });
            updatedMember.roles = (updatedMember.roles ?? []).filter((r) => !(addedRoles?.roles ?? []).includes(r));
        }

        dashboardModule.appendTeamMembers([updatedMember]);
        return updatedMember;
    }

    @Action
    async addPreRegistration(params: CreatePreRegistrationParams): Promise<{
        teamMember?: TeamMember | null;
        error?: string;
        status?: string;
    }> {
        const data = await ApiService.shared.postPreRegistration(params);
        if (!data) {
            return {
                error: `Unable to add team member. An unexpected error occurred.`,
            };
        }
        const { teamMember, success, status } = data;
        console.log('api response', data);
        if (success && teamMember) {
            this.setAddTeamMemberOpen(false);

            dashboardModule.appendTeamMembers([teamMember]);
            dashboardModule.increaseHeading(teamMember);

            this.setSelectedTeamMember(teamMember);
            return { teamMember };
        } else {
            let statusMessage = null;
            switch (status) {
                case InviteUserStatus.NO_USER_FOUND:
                    statusMessage =
                        'This user has already been invited but has not completed their registration. You may add this user to your organization after they complete their registration.';
                    break;
                default:
                    break;
            }

            this.setAddTeamMemberError(statusMessage ?? `Unable to add user. Please try again later.`);
        }

        return { error: 'Unable to add team member.', status };
    }
}
