import { TeamMember } from '@shared/api/DashboardTypes';
import { isBlank, isNumber } from '@shared/util/TypeGuards';

/**
 * Given an optional string, determine if the provided value is a valid email address
 * Allows TLD to be up to 64 characters, which is the max allowed via the DNS spec
 *
 * See [this post](https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression) for more information about the regex used.
 * @param {string | null | undefined} input - the email address
 * @return {boolean} - true if the email is a valid address.
 */
export function isValidEmail(input: string | null | undefined): boolean {
    if (!input) {
        return false;
    }
    // eslint-disable-next-line no-control-regex
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])/.test(
        input.toLowerCase()
    );
}

export function getFullName(person: TeamMember): string {
    return `${person.firstName ?? ''} ${person.lastName ?? ''}`.trim();
}

export function getMemberInitials(person: TeamMember): string | null {
    if (person.firstName && person.lastName) {
        return `${person.firstName.charAt(0) ?? ''} ${person.lastName.charAt(0) ?? ''}`.trim();
    }
    return null;
}

export function splitStringToArray(param: string | string[] | null, delimiter = ','): string[] {
    const values: string[] = [];
    if (!param) {
        return values;
    }

    if (Array.isArray(param)) {
        values.push(...param);
        return values;
    }

    const separated = param.split(delimiter).map((v) => v.trim());
    values.push(...separated);

    return values;
}

/**
 * Generate an array of letters in the alphabet
 * @param {object} options - Optional options object
 * @param {[boolean=false]} options.uppercase - If the resulting array should contain capital letters. Default is false
 * @return {string[]}
 */
export function generateAlphabetArray(options: { uppercase?: boolean } = {}): string[] {
    const { uppercase = false } = options;
    let alphabet = 'abcdefghijklmnopqrstuvwxyz';
    if (uppercase) {
        alphabet = alphabet.toUpperCase();
    }
    const array = alphabet.split('');
    return array;
}

/**
 * Convert a blank string to null
 * @param {string | null | undefined} input
 * @return {string | null}
 */
export function blankToNull(input: string | null | undefined): string | null {
    if (isBlank(input)) {
        return null;
    }
    return input ?? null;
}

/**
 * Given an optional string value, convert it to a number.
 * Returns null if the string could not be parsed into a valid number
 * @param {string | null | undefined} input
 * @return {number | null}
 */
export function stringToNumber(input: string | null | undefined): number | null {
    if (isBlank(input)) {
        return null;
    }

    const num = Number(input);
    if (isNumber(num)) {
        return num;
    }
    return null;
}
