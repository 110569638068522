import { ActionTree, Store } from 'vuex';
import { appModule, initializeStores } from '@util/store-accessor';

const initializer = (store: Store<any>) => initializeStores(store);

export const plugins = [initializer];

export * from '@util/store-accessor';

export interface RootState {}

export const state = (): RootState => ({});

export const actions: ActionTree<RootState, RootState> = {
    /**
     * Called when nuxt starts up the application on the server.
     * Can use this to load any data needed for the application.
     * @param {any} _
     * @param {any} $config
     * @return {Promise<void>}
     */
    async nuxtServerInit(_, { $config }) {
        const domain = $config.apiDomain;
        const path = $config.apiBasePath;
        appModule.setConfig({ apiDomain: domain, apiRootPath: path });
        // await assessmentModule.loadAssessment();
    },
};
