import type { Context, Middleware } from '@nuxt/types';
import { RouteName } from '@shared/routing/PageRoutes';
import User, { Role } from '@shared/models/User';

const systemAdminMiddleware: Middleware = (context: Context) => {
    const { $auth, redirect, error } = context;
    if (!$auth.loggedIn) {
        redirect({ name: RouteName.login });
    }
    const user: Partial<User> = $auth.user;
    if (!user?.roles?.includes(Role.SYSTEM_ADMIN)) {
        error({
            message: 'You do not have permission to view this page.',
            statusCode: 403,
            path: '',
        });
    }
};

export default systemAdminMiddleware;
